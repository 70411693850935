import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Form from "../../components/form"
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from "../../redux/actions/home"
import { updateProfile } from "../../redux/actions/auth"
import toast, { Toaster } from 'react-hot-toast';

const notifySuccess = (message) => toast.success(message, {
    style: {
      padding: '35px',
      color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
      primary: '#a0ca00',
      secondary: '#222c25',
    }
  })

function Profile() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const user = useSelector((user) => user.auth.value.profile);
    const [formData, setFormData] = useState({});
    console.log(JSON.stringify(user), "getProfile")
    useEffect(() => {
        dispatch(getProfile());
        setFormData(user)
    }, [dispatch]);

    useEffect(() => {
        setFormData(user)
        console.log(formData, "HERE USER EFFECT")
    }, [user]);

    const fields = [
        {
            id: "fullname",
            name: "fullname",
            label: "Full Name",
            placeholder: "Enter User's Full Name",
            type: "text",
            // reflectChange: false
        },
        {
            id: "email",
            name: "email",
            label: "Email",
            placeholder: "Enter User's Email",
            type: "mail",
            // reflectChange: false
        },
        {
            id: "mobile",
            name: "mobile",
            label: "User Mobile",
            placeholder: "Enter User's Mobile",
            type: "number",
        }
    ];

    return (
        user &&
        <div className=' bg-lightPrimary'>
            <Toaster />
            <div className='flex'>
                <div className='h-[80vh] shadow-gray-100   shadow w-full m-3 p-4 bg-white rounded-xl'>
                    <p className='text-center'>
                        Update Profile
                    </p>
                    <div className='flex flex-col justify-between h-[75vh]'>
                        <div>
                            <Form
                                fields={fields}
                                formData={formData}
                                onChange={(newFormData) => setFormData(newFormData)}
                            />
                            <div className='flex justify-end'>
                                <button
                                    onClick={() => {
                                        dispatch(updateProfile({...formData, ["id"]: user.id}));
                                        notifySuccess(`Updated Profile`)
                                    }}
                                    className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                    Update Profile
                                </button>
                            </div>
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    localStorage.removeItem("x_access_token");
                                    navigate("/parent/home")
                                  }}
                                className="mb-5 w-[100%] border-2 text-white linear rounded-xl bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                Logout
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Profile