import React, { useState, useEffect } from 'react';
import Form from "../../../components/form"
import { addAdmission, getAdmission, updateAdmission, approveAdmission } from '../../../redux/actions/admin/admission';
import { getClasses } from '../../../redux/actions/admin/classRoom';
import { getBatches } from '../../../redux/actions/admin/batch';
import { getCourses } from '../../../redux/actions/admin/course';
import { getSessions } from '../../../redux/actions/admin/session';
import { getRecordFields } from '../../../redux/actions/admin/fields';
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';

const CrudAdmission = (props) => {
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const { setTitle } = props;
  const { id } = useParams()
  const navigate = useNavigate()
  // setTitle(id ? "Update Admission" : "Add Admission")
  setTitle(id ? "Add Admission" : "Update Admission")

  useEffect(() => {
    if(id != null) dispatch(getAdmission(id))
    dispatch(getRecordFields("Admission"))
  }, [dispatch]);

  const admission = useSelector((admission) => admission.admission.value.singleAdmission);
  const customFields = useSelector((fields) => fields.field.value.singleRecordFields);
  console.log(customFields, "customFields")

  useEffect(() => {
    if (admission) {
      console.log("admission: "+JSON.stringify(admission) )
      setFormData(admission);
    }
  }, [admission]);



  const notifySuccess = (message) => toast.success(message, {
    style: {
      padding: '35px',
      color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
      primary: '#a0ca00',
      secondary: '#222c25',
    }
  })


  const notifyError = (message) => toast.error(message, {
    style: {
      border: '1px solid #fff',
      padding: '35px',
      color: 'red',
    },
    iconTheme: {
      primary: 'red',
      secondary: '#fff',
    }
  })


  const saveSettings = async () => {
    try {
      console.log(formData, "formData");
      if (formData.id) await dispatch(updateAdmission(formData));
      if (!formData.id) await dispatch(addAdmission(formData));

      notifySuccess("Admission Added");
      navigate(`/admin/admissions`);
    } catch (err) {
      notifyError(err.message);
    }
  };

  useEffect(() => {
    dispatch(getClasses({ limit: 50 }))
    dispatch(getBatches({ limit: 50 }))
    dispatch(getCourses({ limit: 50 }))
    dispatch(getSessions({ limit: 50 }))
    // setFormData({})
  }, [dispatch])

  const classes = useSelector((classes) => classes.classRoom.value.data);
  const batches = useSelector((batches) => batches.batch.value.data);
  const courses = useSelector((courses) => courses.course.value.data);
  const sessions = useSelector((sessions) => sessions.session.value.data);

  const approveStudent = async () => {
    try {
      console.log(formData, "formData");
      const admission = await dispatch(approveAdmission(formData));
      notifySuccess("Student & Parent User Added");
      navigate(`/admin/students`);
    } catch (err) {
      notifyError(err.message);
    }
  };


  

  const admissionFields = [{
    personalInfo: [
      {
        id: "name",
        name: "name",
        label: "Name",
        placeholder: "Enter Name",
        type: "text",
      },
      {
        id: "dateOfBirth",
        name: "dateOfBirth",
        label: "Date of Birth",
        placeholder: "Enter Date of Birth",
        type: "datetime",
        isSingle: true,
        isRange: false,
      },
      {
        id: "profile",
        name: "profile",
        label: "Profile Image",
        prevImage: "admission.profile",
        placeholder: "Click here to upload Your Profile Image",
        type: "upload",
      },
    ],
    parentInfo:[
      {
          id: "parentExists",
          name: "parentExists",
          label: "Parent Already Exists ?",
          type: "text",
          desc: "if yes the given details will be validated and assigned as parent",
          type: "switch",
        },
        {
          id: "parentName",
          name: "parentName",
          label: "Parent's Name",
          placeholder: "Enter Parent's Name",
          type: "text",
        },
        {
          id: "parentContact",
          name: "parentContact",
          label: "Parent's Contact",
          placeholder: "Enter Parent's Contact",
          type: "text",
        },
        {
          id: "parentEmail",
          name: "parentEmail",
          label: "Parent's Email",
          placeholder: "Enter Parent's Email",
          type: "mail",
        },
    ],
    customFields:[]
  }
]





// const personalInfo = ['name', 'profile', 'dateOfBirth', 'gender', 'nationality', 'religion', 'caste', 'motherTongue', 'secondLanguage', 'thirdLanguage', 'bloodGroup'];
// const contactInfo = ['address', 'city', 'state', 'country', 'zipCode'];
// const parentInfo = ['parentExists', 'parentName', 'parentContact', 'parentEmail'];
// const healthInfo = ['medicalCondition', 'allergies'];
// const educationalInfo = ['previousSchool', 'previousGrade', 'batchId', 'courseId', 'sessionId', 'classId'];

// const admissionFieldsFormData = {
//   personalInfo: [],
//   contactInfo: [],
//   parentInfo: [],
//   healthInfo: [],
//   educationalInfo: []
// };

// if (admission) {
// admission.forEach(field => {
//   if (personalInfo.includes(field.id)) {
//     admissionFieldsFormData.personalInfo.push(field);
//   } else if (contactInfo.includes(field.id)) {
//     admissionFieldsFormData.contactInfo.push(field);
//   } else if (parentInfo.includes(field.id)) {
//     admissionFieldsFormData.parentInfo.push(field);
//   } else if (healthInfo.includes(field.id)) {
//     admissionFieldsFormData.healthInfo.push(field);
//   } else if (educationalInfo.includes(field.id)) {
//     admissionFieldsFormData.educationalInfo.push(field);
//   }
// });
// }


// console.log("admissionFieldsFormData--->"+JSON.stringify(admissionFieldsFormData));



  

  for (let field of customFields) {
    // admissionFields.push({
    admissionFields[0].customFields.push({
      id: field.name,
      name: field.name,
      label: field.name,
      placeholder: field.name,
      type: field.type,
      toSelect: "name",
      list: field.values ? field.values.split(", ").map((option, index) => ({ id: index + 1, name: option.trim() })) : "",
      selectedvalue: field.name
    })
  }


console.log("formData-->>>>"+JSON.stringify(formData));

  return (
    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
      <Toaster />
      <div className="h-full w-full rounded-xl ">
        {console.log("formData--->:"+JSON.stringify(formData))}
        
        <div className=' border rounded-t-xl '>
          <h1 className='px-8 mt-6 font-semibold text-lg text-gray-900'>Personal Information</h1>
        <Form
          fields={admissionFields[0].personalInfo}
          formData={formData}
          onChange={(newFormData) => setFormData(newFormData)}
          mainDivCss={'grid grid-cols-2 gap-x-8 px-8'}
        />
        </div>
        <div className=' border '>
        <h1 className='px-8 mt-6 font-semibold text-lg text-gray-900'>Parent Information</h1>

        <Form
          fields={admissionFields[0].parentInfo}
          formData={formData}
          onChange={(newFormData) => setFormData(newFormData)}
          mainDivCss={'grid grid-cols-2 gap-x-8 px-8'}
        />
        </div>
        {admissionFields[0].customFields.length >0 && <div className='border rounded-b-xl'>
        <h1 className='px-8 mt-6 font-semibold text-lg text-gray-900 '>Additional Information</h1>

        <Form
          fields={admissionFields[0].customFields}
          formData={formData}
          onChange={(newFormData) => setFormData(newFormData)}
          mainDivCss={'grid grid-cols-2 gap-x-8 px-8'}
        />
        </div>}
        <div className='flex justify-end mt-4'>
           { !formData.student && <button type="button"
            onClick={() => approveStudent(formData)}
            className="border-2 text-white linear rounded-full bg-brand-500 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70"> Approve (Create Student) </button> }
          <button type="button"
            onClick={() => saveSettings(formData)}
            className="w-1/6 border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70"> Save </button>
        </div>
      </div>
    </div>
  );
};

export default CrudAdmission;
