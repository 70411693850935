import { React, useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { postOpenAdmission } from '../../redux/actions/admin/admission';
import { getOpenSetting } from '../../redux/actions/admin/setting';
import { getOpenRecordFields } from '../../redux/actions/admin/fields';
import { useDispatch, useSelector } from 'react-redux'
import DialogCustom from '../../components/dialog';
import toast, { Toaster } from 'react-hot-toast';
import Form from '../../components/form'
// import {
//     FaHome,
//     FaClipboardList,
//     FaRegUser,
//     FaBell
// } from "react-icons/fa";
import brandImg from "assets/img/logo.png";

const notifySuccess = (message) => toast.success(message, {
    style: {
        padding: '35px',
        color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
        primary: '#a0ca00',
        secondary: '#222c25',
    }
})
const notifyError = (message) => toast.error(message, {
    style: {
        border: '1px solid #fff',
        padding: '35px',
        color: 'red',
    },
    iconTheme: {
        primary: 'red',
        secondary: '#fff',
    }
})



const Page1 = () => <div className='bg-yellow-800 h-screen'>Page 1</div>;
const Page2 = () => <div className='bg-green-800 h-screen'>Page 2</div>;
const Page3 = () => <div className='bg-red-800 h-screen'>Page 3</div>;
const Page4 = () => <div>Page 4</div>;

const admissionFields = [{
    personalInfo: [
        {
            id: "name",
            name: "name",
            label: "Name",
            placeholder: "Enter Name",
            type: "text",
        },
        {
            id: "dateOfBirth",
            name: "dateOfBirth",
            label: "Date of Birth",
            placeholder: "Enter Date of Birth",
            type: "datetime",
            isSingle: true,
            isRange: false,
        },
        {
            id: "profile",
            name: "profile",
            label: "Profile Image",
            prevImage: "admission.profile",
            placeholder: "Click here to upload Your Profile Image",
            type: "upload",
        },
    ],
    parentInfo: [
        {
            id: "parentExists",
            name: "parentExists",
            label: "Parent Already Exists ?",
            type: "text",
            desc: "if yes the given details will be validated and assigned as parent",
            type: "switch",
        },
        {
            id: "parentName",
            name: "parentName",
            label: "Parent's Name",
            placeholder: "Enter Parent's Name",
            type: "text",
        },
        {
            id: "parentContact",
            name: "parentContact",
            label: "Parent's Contact",
            placeholder: "Enter Parent's Contact",
            type: "text",
        },
        {
            id: "parentEmail",
            name: "parentEmail",
            label: "Parent's Email",
            placeholder: "Enter Parent's Email",
            type: "mail",
        },
    ],
    customFields: []
}
]




const App = () => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({});
    const [done, setDone] = useState(false);

    const [header, setHeader] = useState("Admission Form")
    const [searchParams] = useSearchParams();
    const code = searchParams.get('code');
    console.log(code, "CODE")
    const settings = useSelector((settings) => settings.setting.businessSettings);
    const customFields = useSelector((fields) => fields.field.value.singleRecordFields);

    // console.log("settings: "+JSON.stringify(settings))


    useEffect(() => {
        dispatch(getOpenSetting(code));
        dispatch(getOpenRecordFields({ code, record: "Admission" }));

    }, [dispatch, code]);

    useEffect(() => {
        admissionFields[0].customFields = []
        for (let field of customFields) {
            // admissionFields.push({
            admissionFields[0].customFields.push({
                id: field.name,
                name: field.name,
                label: field.name,
                placeholder: field.name,
                type: field.type,
                toSelect: "name",
                list: field.values ? field.values.split(", ").map((option, index) => ({ id: index + 1, name: option.trim() })) : "",
                selectedvalue: field.name
            })
        }

        // setMergedFields(updatedFields);
    }, [customFields]);

    useEffect(() => {
        // Check if settings is available and enableAdmission is defined
        if (settings && typeof settings.config !== 'undefined' && typeof settings.config.enableAdmission !== 'undefined') {
            if (!settings.config.enableAdmission) {
                notifyError("Admissions have been disabled right now.");
            }
        }
    }, [settings]);


    const saveSettings = async () => {
        try {
            console.log(formData, "formData");
            const admission = await dispatch(postOpenAdmission({ code, formData }));
            notifySuccess("Admission Added");
            setDone(true)
        } catch (err) {
            notifyError(err.message);
        }
    };


    return (
        <div className="flex flex-col h-screen p-3">
            <div className='bg-white w-full p-3 rounded-xl shadow-lg h-fit' >
                <header className='flex flex-col items-center justify-center w-full'>
                    <img src={settings.profile ? `${process.env.REACT_APP_URL + "images" + settings.profile}` : brandImg}
                        alt=""
                        className='rounded-xl shadow-lg w-[108px]' />
                    <h1 className='font-poppins mt-4 text-3xl font-semibold'> {settings?.name} </h1>
                    <p className='text-xl underline'> {header} </p>
                </header>
                {console.log("t/f: " + (settings?.config?.enableAdmission))}
                {/* {console.log("settings: "+(JSON.stringify(settings)))} */}
                {console.log("settings: " + JSON.stringify(settings))}
                {

                    settings?.config?.enableAdmission &&
                    <div >

                        {!done &&
                            <>
                                <div className="h-full w-full rounded-xl ">
                                    {console.log("formData--->:" + JSON.stringify(formData))}

                                    <div className=' border rounded-t-xl '>
                                        <h1 className='px-8 mt-6 font-semibold text-lg text-gray-900'>Personal Information</h1>
                                        <Form
                                            fields={admissionFields[0].personalInfo}
                                            formData={formData}
                                            onChange={(newFormData) => setFormData(newFormData)}
                                            mainDivCss={'grid grid-cols-1 gap-x-8 py-4'}
                                        />
                                    </div>
                                    <div className=' border '>
                                        <h1 className='px-8 mt-6 font-semibold text-lg text-gray-900'>Parent Information</h1>

                                        <Form
                                            fields={admissionFields[0].parentInfo}
                                            formData={formData}
                                            onChange={(newFormData) => setFormData(newFormData)}
                                            mainDivCss={'grid grid-cols-1 gap-x-8 py-4'}
                                        />
                                    </div>
                                    {admissionFields[0].customFields.length > 0 && <div className='border rounded-b-xl'>
                                        <h1 className='px-8 mt-6 font-semibold text-lg text-gray-900 '>Additional Information</h1>

                                        <Form
                                            fields={admissionFields[0].customFields}
                                            formData={formData}
                                            onChange={(newFormData) => setFormData(newFormData)}
                                            mainDivCss={'grid grid-cols-1 gap-x-8 py-4'}
                                        />
                                    </div>}
                                </div>
                                <div className='flex justify-end'>
                                    <button type="button"
                                        onClick={() => saveSettings(formData)}
                                        className="w-1/3 border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70"> Save </button>
                                </div>
                            </>
                        }
                        {
                            done &&
                            <div className='mt-5 p-4 text-2xl text-brand-500 text-center'>
                                Thanks for the submission, we will get back to you soon.
                            </div>
                        }

                    </div>
                }
            </div>


            <Toaster />
        </div>
    );
};

export default App;
