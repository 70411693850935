import Card from "components/card";

const defaultStyling =  {
  iconContStyles: "bg-brand-500",
  iconStyles: "text-white",
  border: "border-solid border-2 border-yellow-100"
}

const Widget = ({ icon, title, subtitle, styling }) => {
  styling = styling ? styling : defaultStyling
  return (
    <Card extra={"!flex-row flex-grow items-center rounded-[20px]  shadow-3xl" + ` ${styling.border}`}>
      <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
        <div className={"rounded-full p-3 dark:bg-navy-700" + ` ${styling.iconContStyles}` }>
          <span className={"flex items-center dark:text-white" + ` ${styling.iconStyles}`}>
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm font-medium text-gray-600">{title}</p>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {subtitle}
        </h4>
      </div>
    </Card>
  );
};

export default Widget;
