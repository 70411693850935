// import React from "react";

// const InputField = React.forwardRef((props, ref) => {
//   const { label, id, extra, type, placeholder, variant, state, disabled, onChange, value, required, errors, name } = props;

//   return (
//     <div className={`${extra}`}>
//       <label
//         htmlFor={id}
//         className={`text-sm text-navy-700 dark:text-white ${
//           variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
//         }`}
//       >
//         {label}
//       </label>
//       <input
//         required={required}
//         disabled={disabled}
//         value={value}
//         onChange={onChange}
//         type={type}
//         id={id}
//         placeholder={placeholder}
//         ref={ref}
//         className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
//           disabled === true
//             ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
//             : state === "error"
//             ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
//             : state === "success"
//             ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
//             : "border-gray-200 dark:!border-white/10 dark:text-white"
//         }`}
//       />
//       {errors && errors[id] && <p className="text-red-600 text-xs ml-3 mt-1">{errors[id]}</p>}
//     </div>
//   );
// });

// export default InputField;


import React, { useState } from "react";

const InputField = React.forwardRef((props, ref) => {
  const { label, id, extra, type, placeholder, variant, state, disabled, onChange, value, required, errors, name } = props;
  
  const [isFocused, setIsFocused] = useState(false); // Add state for focus

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-navy-700 dark:text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <input
        required={required}
        disabled={disabled}
        value={value}
        onChange={onChange}
        type={type}
        id={id}
        placeholder={placeholder}
        ref={ref}
        onFocus={() => setIsFocused(true)} // Add onFocus handler
        onBlur={() => setIsFocused(false)} // Add onBlur handler
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none hover:border-brand-500  ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : isFocused // Highlight when focused
            ? "border-brand-500  placeholder:text-gray-700 dark:!border-brand-400 dark:!text-brand-400 dark:placeholder:!text-brand-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
      />
      {errors && errors[id] && <p className="text-red-600 text-xs ml-3 mt-1">{errors[id]}</p>}
    </div>
  );
});

export default InputField;
