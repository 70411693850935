import Switch from "components/switch";

const SwitchField = (props) => {
  const { id, label, desc, mt, mb, onChange, checked } = props;
  return (
    <div className={`flex justify-between border-solid rounded-xl p-2 border-2 border-black ${mt} ${mb} items-center`}>
      <label
        htmlFor={id}
        className="max-w-[80%] hover:cursor-pointer lg:max-w-[65%]"
      >
        <h5 className="text-base font-bold text-navy-700 dark:text-white">
          {label}
        </h5>
        <p className={`text-base text-gray-600`}>{desc}</p>
      </label>
      <div className="ml-5 flex items-center">
        <Switch id={id}  checked={checked} onChange={onChange} />
      </div>
    </div>
  );
};

export default SwitchField;
