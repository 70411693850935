import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createHeaders } from "./headers";

// export const getBusinesses = createAsyncThunk("business/get", async (query) => {
//   console.log(query);
//   let filteredQuery = Object.fromEntries(
//     Object.entries(query).filter(
//       ([key, value]) => value != null && value !== ""
//     )
//   );
//   let params = new URLSearchParams(filteredQuery);
//   let response = await fetch(
//     `${process.env.REACT_APP_URL}api/business/?${params.toString()}`,
//     {
//       headers: createHeaders(),
//     }
//   );
//   let json = await response.json();
//   console.log(json);
//   return json;
// });

// export const StudentsParentsCount = createAsyncThunk("StudentsParentsCount/get", async () => {
//   let response = await fetch(
//     `${process.env.REACT_APP_URL}api/studentparentcount`,
//     {
//       headers: createHeaders(),
//     }
//   );
//   let json = await response.json();
//   console.log(json);
//   return json;
// });

// export const getBusiness = createAsyncThunk(
//   "business/getSingleBusiness",
//   async (businessId) => {
//     // let params = new URLSearchParams(filteredQuery);
//     let response = await fetch(
//       `${process.env.REACT_APP_URL}api/business/fetchSingleBusiness/${businessId}`,
//       {
//         headers: createHeaders(),
//       }
//     );
//     let json = await response.json();
//     console.log(json);
//     return json;
//   }
// );

export const addBusiness = createAsyncThunk("business/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/open/business/addbusiness`, {
    method: "POST",
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const syncUserModels = createAsyncThunk("sync/userModel", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/open/business/syncusermodels`, {
    method: "POST",
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});


export const updateBusiness = createAsyncThunk(
  "business/update",
  async (data) => {
    console.log(data, "ID and DATA for update");
    let response = await fetch(`${process.env.REACT_APP_URL}admin/open/business/updatebusiness`, {
      method: "PUT", // or 'PATCH' depending on your API
      headers: createHeaders(),
      body: JSON.stringify({ ...data }), // Include the id in the request body
    });
    let json = await response.json();
    return json;
  }
);

// export const signupWelcomeMail = createAsyncThunk("signup/welcomeMail", async (data) => {
//   const response = await fetch(`${process.env.REACT_APP_URL}admin/open/auth/signup-welcome-email`, {
//     method: "POST",
//     headers: createHeaders(),
//     body: JSON.stringify(data),
//   });
//   const json = await response.json();
//   return json;
// });

// export const deleteBusiness = createAsyncThunk("business/delete", async (id) => {
//   console.log(id, "ID for delete");
//   let response = await fetch(`${process.env.REACT_APP_URL}api/business/${id}`, {
//     method: 'DELETE',
//     headers: createHeaders(),
//   });
//   let json = await response.json();
//   return json;
// });

export const businessesSlice = createSlice({
  name: "business",
  initialState: {
    value: {
      isError: false,
      isLoading: false,
      status: "",
      // parentsCount:0,
      // studentsCount:0,
      data: {
        count: 0,
        activeCount: 0,
        inactiveCount: 0,
        rows: [],
        totalStudents: 0,
        totalParents: 0,
      },
      userInput: {
        name: "",
        code: "",
        type: "",
        mobile: "",
        status: false,
        email: "",
        registeredAddress: "",
        country: "",
        referCode: "",
        category: "",
        signupSource: "",
        panNo: "",
        gstNo: "",
      },
    },
  },
  reducers: {
    // updateUserInput: (state, action) => {
    //   console.log(
    //     "inside updateUserInput redux --------------------------->" +
    //       JSON.stringify(action.payload)
    //   );
    //   state.value.userInput = { ...state.value.userInput, ...action.payload };
    // },
    // resetUserInput: (state) => {
    //   // Reset all userInput values to ""
    //   // Object.keys(state.value.userInput).forEach((key) => {
    //   //   state.value.userInput[key] = "";
    //   // });

    //   // Object.keys(state.value.userInput).map((key) => {
    //   //   if (key === "status") {
    //   //     return [key, false];
    //   //   } else {
    //   //     return [key, ""];
    //   //   }
    //   // })
    //   state.value.userInput = Object.fromEntries(
    //     Object.keys(state.value.userInput).map((key) => {
    //       if (key === "status") {
    //         return [key, false];
    //       } else {
    //         return [key, ""];
    //       }
    //     })
    //   );
    // },
  },
  extraReducers: (builder) => {
    builder
     
      .addCase(addBusiness.fulfilled, (state, action) => {
        // state.value.userInput = Object.fromEntries(
        //   Object.keys(state.value.userInput).map((key) => [key, ""])
        // );
        // state.value.userInput = Object.fromEntries(
        //   Object.keys(state.value.userInput).map((key) => {
        //     if (key === "status") {
        //       return [key, false];
        //     } else {
        //       return [key, ""];
        //     }
        //   })
        // );
        
      })
      .addCase(addBusiness.pending, (state) => {
        state.status = "Adding state. Please wait a moment...";
      })
      .addCase(addBusiness.rejected, (state) => {
        state.status = "Failed to add state...";
      })
     
      
  },
});

// export const { updateUserInput, resetUserInput } = businessesSlice.actions;
export default businessesSlice.reducer;
