import { React } from 'react'
import { useLocation , useNavigate } from 'react-router-dom';
import { IoEnter } from "react-icons/io5";
import { useSelector } from 'react-redux';



function Subjects() {
    // const location = useLocation();
    const navigate = useNavigate();
    // console.log(location.state)
    const home = useSelector((home) => home.home.value);
    const { subjects, enrolledClass } = home
    console.log("home --- "+JSON.stringify(home));
    return (
        <div className=' bg-lightPrimary h-screen p-4'>
            <div className='p-2 bg-white rounded-xl '>
                {/* <p className='text-xl font-regular text-brand-500 text-center m-3' >
                    Subjects for <span className='font-bold'> {enrolledClass.name} </span>
                </p> */}
                {
                    subjects.length == 0 && 
                    <p className='text-center mt-5'> No subjects found </p>
                }
                {
                    subjects.map((subject, index) => {
                        return (
                            <div 
                            className={`h-3/12 my-5 border border-brand-500 shadow-gray-200  shadow-xl w-full mt-2 p-4  rounded-xl flex items-center justify-between`}
                            onClick={() => { navigate(`/parent/subject/${subject.id}`) }}
                            >
                            
                                <div>
                                    <h1 className='font-poppins font-medium text-xl'> {subject.name} </h1>
                                </div>
                                <div className='bg-brand-500 shadow min-h-[64px] min-w-[96px]  text-white rounded-xl flex flex-col items-center justify-center '>
                                    <IoEnter className='text-2xl' />
                                    <p className='text-xs'> view materials </p>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default Subjects