


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from './headers';


export const getDistricts = createAsyncThunk("district/get", async (query) => {
    console.log(query)

    let filteredQuery = Object.fromEntries(
      Object.entries(query).filter(([key, value]) => value != null && value !== '')
  );
  let params = new URLSearchParams(filteredQuery);

  let response = await fetch(`${process.env.REACT_APP_URL}api/district?${params.toString()}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  return json;
});

export const addDistrict = createAsyncThunk("district/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}api/district/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

    

export const updateDistrict = createAsyncThunk("district/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}api/district/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});

export const deleteDistrict = createAsyncThunk("district/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}api/district/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const districtsSlice = createSlice({
  name: 'district',
  initialState: {
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        activeCount: 0,
        inactiveCount: 0,
        rows: []
      }
    }
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getDistricts.fulfilled, (state, action) => {
        // console.log(action.payload, "DATA FETCHED")
        state.value.data.rows = action.payload.rows
        state.value.data.count = action.payload.count
        state.value.data.activeCount = action.payload.activeDistricts
        state.value.data.inactiveCount = action.payload.inactiveDistricts
        state.value.status = action.payload.message
        
        // console.log('Updated state:', state);
      })
      .addCase(getDistricts.pending, (state) => {
        state.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getDistricts.rejected, (state) => {
        state.status = "Failed to  fetch data..."
      })
      .addCase(updateDistrict.fulfilled, (state, action) => {
        console.log(action.payload.message, "action.payload.message")
        state.value.status = action.payload.message

        // find the index of the state with the updated id
        // const index = state.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the state at the found index with the updated state
        //   state.value.data[index] = action.payload;
        // }
      })
      .addCase(updateDistrict.pending, (state) => {
        state.status = "Updating state. Please wait a moment...";
      })
      .addCase(updateDistrict.rejected, (state) => {
        state.status = "Failed to update state...";
      })
      .addCase(deleteDistrict.fulfilled, (state, action) => {
        // filter out the state with the deleted id
        state.value.status = action.payload.message
        // console.log(action.payload.message, "action.payload.message")
      })
      .addCase(deleteDistrict.pending, (state) => {
        state.status = "Deleting state. Please wait a moment...";
      })
      .addCase(deleteDistrict.rejected, (state) => {
        state.status = "Failed to delete state...";
      })
      .addCase(addDistrict.fulfilled, (state, action) => {
        // state.value.data.push(action.payload); // add the new state to the list
     })
     .addCase(addDistrict.pending, (state) => {
        state.status = "Adding state. Please wait a moment...";
     })
     .addCase(addDistrict.rejected, (state) => {
        state.status = "Failed to add state...";
     });
  }
})

export default districtsSlice.reducer
