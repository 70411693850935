

import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";
import InputField from "components/fields/InputField";
// import { FcGoogle } from "react-icons/fc";
import toast, { Toaster } from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { checkEntity, login } from '../../../redux/actions/auth';
import { getOpenSetting } from '../../../redux/actions/admin/setting';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";



export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((auth) => auth.auth.value.isLoggedIn);
  const status = useSelector((auth) => auth.auth.value.status);
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [businessLogo, setBusinessLogo] = useState("")
  const [validation, setValidation] = useState({ valid: true, error: "" })
  const [institutionCode, setInstitutionCode] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const instituteCode = searchParams.get("code");
  console.log("instituteCode: "+instituteCode);

  const handleInstitutionCodeSubmit = async () => {
    const action = await dispatch(checkEntity({code: instituteCode||institutionCode}))
    if (action.payload.auth) {
      notifySuccess("valid institute code");
    } else {
      notifyError("invalid institute code");
    }
    console.log("action --"+JSON.stringify(action.payload.business.code));
    const apicall =await dispatch(getOpenSetting(instituteCode||institutionCode))
    // console.log("apicall ---"+JSON.stringify(apicall.payload.profile));
    setBusinessLogo(apicall.payload.profile)
    if(action.payload.auth == true) {
      setValidation({valid: true, error: ""})
      return setShowLogin(true)
    };
    notifyError(action.payload.message)
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  // useEffect(() => {
  //   async function fetchData(){
  //     const action =await dispatch(fetchSettings())
  //     // console.log("action --"+JSON.stringify(action.payload.profile));
  //     setBusinessLogo(action.payload.profile)
  //   }
  //   fetchData()
    
  // }, []);


  useEffect(() => {
    validate(username, password);
  }, [username, password]);

  const validate = (username, password) => {
    if (username === '' && password === '') {
      return;
    }

    // username must be 3-16 characters (letters, numbers, underscores and hyphens only)
    const usernameRegex = /^[a-zA-Z0-9_-]{3,16}$/;

    // password must be 8-20 characters, at least one letter and one number
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/;

    if (!usernameRegex.test(username)) {
      setValidation({ valid: false, error: "Invalid username. Must be 3-16 characters long and can include letters, numbers, underscores and hyphens only." });
    }  else {
      setValidation({ valid: true, error: "" });
    }
  };
  
  const validateCode = (code) => {
    if (code === '') {
      return;
    } 

    // code must be all lowercase
    const institutionCodeRegex = /^[a-z]+$/;

    if (!institutionCodeRegex.test(code)) {
      setValidation({ valid: false, error: "Invalid code. Must be must contain all lower case letters only." });
    }  else {
      setValidation({ valid: true, error: "" });
    }
  };

  const handleLogin = async (username, password) => {
    if (!validation.valid) return notifyError(validation.error)
    const loginCall = await dispatch(login({ username, password, code: institutionCode }))
    if (!loginCall.payload.auth) {
      return notifyError(loginCall.payload.message)
    } else {
      notifySuccess(loginCall.payload.message)
      if(loginCall.payload.user.role.name == "Parent") {
        navigate("/parent/home")
      } else {
        navigate("/admin/default")
      }
      return 0
    }
  };
  const notifySuccess = (message) => toast.success(message, {
    style: {
      padding: '35px',
      color: '#a0ca00',
    },
    iconTheme: {
      primary: '#a0ca00',
      secondary: '#222c25',
    }
  })
  const notifyError = (message) => toast.error(message, {
    style: {
      border: '1px solid #fff',
      padding: '35px',
      color: 'red',
    },
    iconTheme: {
      primary: 'red',
      secondary: '#fff',
    }
  })
  useEffect(() => {
    if (instituteCode) {
      handleInstitutionCodeSubmit();
      setInstitutionCode(instituteCode)
    }
  }, [instituteCode]);
  return (
    <div className="mb-5 flex flex-col items-center justify-center p-5 lg:flex-row lg:justify-start shadow-lg rounded-lg">
  <div className="w-full max-w-full flex flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
    {/* {!validation.valid && <p className='text-red-500 text-xs m-5 text-center'>{validation.error}</p>} */}
    {!showLogin ? (
      <>
        <h4 className="mb-2.5 text-4xl font-bold text-center text-navy-700 dark:text-white">Sign In</h4>
        <p className="mb-5 ml-1 text-base text-gray-600 text-center">Enter your institution code to proceed!</p>

        <InputField
          variant="auth"
          extra="mb-3 w-full"
          label="Institution Code*"
          placeholder="Enter your institution code"
          id="institutionCode"
          type="text"
          value={institutionCode}
          onChange={(e) => {
            validateCode(e.target.value)
            setInstitutionCode(e.target.value)}
          }
        />
        <button onClick={handleInstitutionCodeSubmit}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Proceed
        </button>
        <h1 className='my-2'>Don't have an account?<span className='text-brand-500 font-bold hover:cursor-pointer' onClick={(e)=>{navigate('/auth/signup')}}> Signup</span></h1>
      </>
    ) : (
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <h4 className="mb-2.5 text-4xl font-bold text-center text-navy-700 dark:text-white mt-10">Sign In</h4>
        <p className="mb-5 ml-1 text-base text-gray-600 text-center">Enter your email and password to sign in!</p>
        
        <InputField
          variant="auth"
          extra="mb-3"
          label="Username*"
          placeholder="Enter your username"
          id="username"
          type="text"
          value={username}
          onChange={(e) => {
            const newUsername = e.target.value
            setUsername(newUsername);
          }}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={(e) => {
            const newPassword = e.target.value
            setPassword(newPassword);
          }}
        />
        
        <div className="mb-4 flex items-center justify-center px-2">
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            onClick={() => {
              setShowLogin(false)
            }}
          >
            Re-enter Institution Code
          </a>
          {/* <Link
            to={`/auth/forget-password?code=${institutionCode}`}
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Forgot Password?
          </Link> */}
        </div>
        <button onClick={() => {
          handleLogin(username, password);
        }}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign in
        </button>
        <div className="my-4 flex items-center justify-end px-2 ">
          
          <Link
            to={`/auth/forget-password?code=${institutionCode}`}
            className="text-sm font-medium text-red-500 hover:text-red-600 dark:text-white"
          >
            Forgot Password?
          </Link>
        </div>
      </motion.div>
    )}
    <Toaster />
  </div>
</div>

  );
}

