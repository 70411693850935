import { React, useState, useEffect } from 'react'
import { getPeriods, getSinglePeriod } from '../../../redux/actions/admin/period';
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";
import {
    FiSearch,
    FiEdit2,
    FiTrash,
} from "react-icons/fi";

export const SinglePeriod = (props) => {
    const { setTitle } = props
    const { id } = useParams()
    console.log(id, "PERIOD ID")
    const navigate = useNavigate();
    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    const period = useSelector((periods) => periods.period.value.singlePeriod);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPeriods({}))
        dispatch(getSinglePeriod(id))
        if(period) {
            setTitle("Period - " + period.name)
        }
    }, [dispatch])

    const [searchQuery, setSearchQuery] = useState('');

    // Update the onChange event handler for the input field
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter the attendances based on the search query
    const filteredAttendances = period ? period.attendances.filter(attendance =>
        attendance.student.admission.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) : [];


    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>
                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            value={searchQuery}
                                            onChange={handleSearch}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                </div>
                                <div className='flex  justify-end'>

                                </div>
                            </div>
                        </TableCaption>
                        {filteredAttendances.length > 0 &&
                            <div className="">
                                <TableHeader>

                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-2/12">  </TableHead>
                                        <TableHead className="w-6/12"> Student </TableHead>
                                        <TableHead className="w-6/12"> Status </TableHead>
                                        <TableHead className="w-2/12">  </TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {filteredAttendances.map((attendance) => (
                                        <TableRow key={attendance.id}>

                                            <TableCell className="font-medium">
                                            </TableCell>

                                            <TableCell className="font-medium">
                                                <h4 className=''> {attendance.student.admission.name} </h4>
                                            </TableCell>

                                            <TableCell className="font-medium">
                                                <h4 className=''> {attendance.status} </h4>
                                            </TableCell>

                                            <TableCell className="font-medium">
                                            </TableCell>

                                        </TableRow>
                                    ))}


                                </TableBody>
                            </div>
                        }
                    </Table>
                    {filteredAttendances.length == 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No Attendances Found! </p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
};

export default SinglePeriod