import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";

import { getDashboardData } from "../../../redux/actions/admin/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const Dashboard = () => {
  const dispatch = useDispatch()

  const dashboardData = useSelector((data) => data.dashboard.value.data);


  useEffect(() => {
    dispatch( getDashboardData() )
  }, [dispatch])
  

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Students"}
          subtitle={dashboardData.totalStudents}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Total Parents"}
          subtitle={dashboardData.totalParents}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Enrollments"}
          subtitle={dashboardData.totalEnrollments}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Total Courses"}
          subtitle={dashboardData.totalCourses}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Classes"}
          subtitle={dashboardData.totalClasses}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Completed Tasks"}
          subtitle={dashboardData.totalCompletedTasks}
        />
      </div>

      {/* Charts */}
      {
        dashboardData?.attendanceStatuses && 
      <div className="mt-5">
        <TotalSpent  data={dashboardData.attendanceStatuses} />
      </div>
      }
      {
        dashboardData?.classStats && 
      <div className="mt-5">
        <WeeklyRevenue data={dashboardData.classStats} />
      </div>
      }

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        <div>
          {/* <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          /> */}
        </div>

        {/* Traffic chart & Pie Chart */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div>

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />  */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;

