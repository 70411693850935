

// working 2 click prob
import React, { useEffect, useState } from "react";
import {
  CaretRightIcon,
  CaretLeftIcon,
  CalendarIcon,
  CheckIcon,
} from "@radix-ui/react-icons";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/shadcn/accordian";
import moment from "moment";
// import { getTaskTypes } from "redux/actions/user/taskType";
import {
  getTaskTypes,
  addTaskLog,
  updateTaskLog,
  deleteTaskLog,
} from "../../../redux/actions/user/taskType";
import { useSelector, useDispatch } from "react-redux";
import DialogCustom from "../../../components/dialog";

const CustomDatePicker = ({
  initialDate,
  onChange,
  taskTypeData,
  setTaskTypeData,
  selectedDate,
  setSelectedDate,
  studentId,
}) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (studentId) {
      const fetchData = async () => {
        try {
          const action = await dispatch(
            getTaskTypes({
              selectedDate: selectedDate.toISOString().split("T")[0],
              studentId,
            })
          );
          const taskTypesData = action.payload.rows; // Update this line to access the actual data
          console.log("taskTypesData:", taskTypesData);
          setTaskTypeData(taskTypesData);
        } catch (error) {
          console.error("Error fetching task types:", error);
        }
      };
  
      fetchData();
    }
  }, [selectedDate, studentId]); // Add studentId to the dependency array
  


  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange(date);
  };
  const formattedDate = `${selectedDate.getDate()} ${selectedDate.toLocaleString(
    "default",
    { month: "short" }
  )} ${selectedDate.getFullYear()}`;

  return (
    <div className="flex w-full">
      <div className="m-[10px] my-4 flex h-10  w-full justify-center ">
        <button
          className="rounded-lg bg-brand-500 px-[14px] py-2 text-white "
          onClick={() =>
            handleDateChange(
              new Date(selectedDate.getTime() - 24 * 60 * 60 * 1000)
            )
          } // Decrease date by one day
        >
          <CaretLeftIcon />
        </button>

        <div className="mx-3  flex w-[100%] rounded-xl">
          <span className="flex w-[75%] items-center justify-center rounded-l-xl bg-white">
            {formattedDate}
          </span>
          <span className="flex w-[25%] items-center justify-center rounded-r-xl bg-gray-100">
            {" "}
            <CalendarIcon />
          </span>
        </div>

        <button
          className="rounded-lg bg-brand-500 px-[14px] py-2 text-white"
          onClick={() =>
            handleDateChange(
              new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000)
            )
          } // Increase date by one day
        >
          <CaretRightIcon />
        </button>
      </div>
    </div>
  );
};

const Task = (props) => {
  const dispatch = useDispatch();
  // const student =  useSelector((state) => state.auth.value.profile.students[0]);
  // const student = useSelector((state) => {
  //   const profile = state.auth.value.profile;
  //   return profile && profile.students && profile.students[0];
  // });

  const student = useSelector(state => state.auth.activeStudent);
// console.log("activeStudent---"+JSON.stringify(activeStudent));
  // const studentId = student.id;
  const studentId = student?.id;
  console.log("studentId:::" + JSON.stringify(studentId));
  // console.log("muzammil::--")


  // const { user, student } = props;
  // console.log("user: "+JSON.stringify(user));
  console.log("student:-- " + JSON.stringify(student));
  const initialDate = new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [taskTypeData, setTaskTypeData] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [taskData, setTaskData] = useState({});
  let doneDate;
  let dateParts;

  // Extract the date and time parts
  let date; // Date part
  let time; // Time part
  // const dispatch = useDispatch();

  const handleClick = () => {
    setIsActive(!isActive);
    console.log("isActive " + isActive);
  };

  const confirmDone = async (data) => {
    setIsDialogOpen(true);
    setTaskData(data);
    console.log("data ---"+JSON.stringify(data));
  };

  const handleTaskCompletion = async (data) => {
    const currentDate = moment(); // Use moment() to get the current date and time
    const doneDate = currentDate.format(); // Format the current date and time using moment.js
    // const currentDate = new Date();
    // const doneDate =
    //   currentDate.toISOString().split("T")[0] +
    //   " " +
    //   currentDate.toLocaleTimeString("en-US", { hour12: false }) +
    //   "+05:30";

    if (data.taskLogLength >= 0) {
      const action = await dispatch(
        addTaskLog({ ...data, doneDate, done: true })
      );
    }
    //  else {

    //   const action = await dispatch(deleteTaskLog(data.userId));
    // }

    const action = await dispatch(
      getTaskTypes({
        selectedDate: selectedDate.toISOString().split("T")[0],
        studentId: student.id,
      })
    );
    const updatedTaskTypesData = action.payload.rows;
    console.log("Updated taskTypesData:", updatedTaskTypesData);
    setTaskTypeData(updatedTaskTypesData);
    setIsDialogOpen(false);
  };

  const handleChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    console.log("Selected date:", formattedDate);
  };

  const formatDate = (task) => {
    doneDate =
      task.tasklogs &&
      task.tasklogs[0] &&
      moment(task.tasklogs[0].doneDate).format("YYYY-MM-DD HH:mm:ss");
    if (doneDate) {
      dateParts = doneDate.split(" ");
      date = dateParts[0];
      time = dateParts[1];
    }
  };

  let isTaskCompleted 
  let isTaskClickable = false

  return (
    <div className="flex h-screen   w-full flex-col items-center">
      <div className="h-screen  w-full p-1">
        {/* {console.log("student.id:::" + JSON.stringify(student.id))} */}
        {console.log("student.id:::---" + JSON.stringify(studentId))}
        {console.log("taskTypeData:::---" + JSON.stringify(taskTypeData))}
        <CustomDatePicker
          initialDate={initialDate}
          onChange={handleChange}
          taskTypeData={taskTypeData}
          setTaskTypeData={setTaskTypeData}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          studentId={studentId}
        />

        {/* {console.log("taskTypeData :------" + !taskTypeData.length)} */}
        {!taskTypeData.length && (
          <div className="flex h-[60%] w-full items-center justify-center p-5 text-center text-3xl font-medium text-gray-800">
            No tasks found for this date
          </div>
        )}

        <Accordion type="single" collapsible className="w-full ">
          {taskTypeData &&
            taskTypeData.map((taskType, index) => (
              <AccordionItem
                key={index}
                value={`item-${index}`}
                className="m-2"
              >
                <AccordionTrigger
                  onClick={handleClick}
                  className={`${
                    isActive ? "rounded-b-none" : ""
                  } h-fit w-full rounded-xl bg-white p-4 text-xl font-medium text-brand-500 shadow shadow-gray-100 `}
                >
                  {taskType.name}
                </AccordionTrigger>
                <div className="rounded-b-xl bg-white ">
                  <AccordionContent className="py-4 ">
                    {taskType.tasks.map((task, taskIndex) => (
                      <div key={taskIndex} className=" ">
                        <div className="flex items-center justify-between px-3 pr-10">
                          <div className="flex w-full items-center   justify-between">
                            {console.log("taskkkk :"+JSON.stringify(task))}
                            {/* {console.log("t/f :"+JSON.stringify(task.tasklogs.studentId==student.id ))} */}
                            {/* {isTaskCompleted = task.tasklogs.some(log => log.studentId === student.id )} */}
                            
                            {console.log("t/f :"+isTaskCompleted)}
                            {/* {console.log(" task.tasklogs.studentId==student.id :"+  task.tasklogs.studentId==student.id)} */}
                            <div className="flex items-center justify-center  ">
                            {console.log("isTaskCompleted--->>>checkbox"+isTaskCompleted)}
                              <div
                                className={`flex h-5 w-5 items-center justify-center rounded border-2  ${
                                  task.tasklogs.length > 0
                                    ? "bg-brand-500 "
                                    : "bg-white"
                                }`}
                                
                                onClick={() => {
                                    // if (!(task.tasklogs.length > 0) && !isTaskCompleted) {
                            {isTaskCompleted = task.tasklogs.some(log => log.studentId === student.id )}

                                      console.log("!isTaskCompleted :"+!isTaskCompleted);
                                    if (!isTaskCompleted) {
                                    console.log("task.id :"+task.id);
                                    const params = {
                                      taskId: task.id,
                                      taskLogLength: task.tasklogs.length,
                                    };
                                    // Check if student has userId property
                                    console.log("task::--"+task)
                                    console.log("task.userId::--"+task.userId)
                                    // console.log("task::--"+task)
                                    if (task.userId) {
                                      params.userId = student.userId;
                                    } else {
                                      params.studentId = student.id;
                                    }
                                    confirmDone(params);
                                  }
                                }}
                              >
                                <CheckIcon className="text-white" />
                              </div>
                              <div
                                className={`ml-2 flex max-w-[160px] items-center justify-center text-lg  ${
                                  task.tasklogs.length > 0 
                                    ? "text-gray-900 line-through"
                                    : ""
                                }`}
                              >
                                {task.task}
                              </div>
                            </div>
                            {/* <div>{task.tasklogs && task.tasklogs[0] && moment(task.tasklogs[0].doneDate).format("YYYY-MM-DD HH:mm:ss")}</div> */}
                            <div className="flex flex-col text-xs font-light text-gray-500">
                              {formatDate(task)}
                              <div>
                                {task.tasklogs && task.tasklogs.studentId==student.id && task.tasklogs[0] && date && (
                                  <h1>Completed At</h1>
                                )}
                              </div>
                              <div>
                                
                                {task.tasklogs && task.tasklogs.studentId==student.id && 
                                  task.tasklogs[0] &&
                                  date &&
                                  date}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionContent>
                </div>
              </AccordionItem>
            ))}
        </Accordion>
      </div>
      <DialogCustom
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        dialogTitle="Confirm Task submission"
        dialogWidth=""
        dialogDesc="Are you sure you want to mark this task as Done?"
        content={
          <div className="flex flex-row-reverse">
            <button
              onClick={() => handleTaskCompletion(taskData)}
              className=" linear rounded-xl border-2 border-white  bg-brand-700 px-2 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-red-700 hover:!bg-white hover:text-red-500 hover:shadow-lg  "
            >
              Done
            </button>
            <button
              onClick={() => {
                setIsDialogOpen(false);
              }}
              className="linear mr-4 rounded-xl border-2 border-white bg-red-700 px-2 py-2 text-center text-base font-medium text-white transition duration-200 hover:border-2 hover:border-brand-700 hover:!bg-white hover:text-brand-500 hover:shadow-lg  "
            >
              Cancel
            </button>
          </div>
        }
      />
    </div>
  );
};

export default Task;




