
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getTasks = createAsyncThunk("task/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/task?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addTask = createAsyncThunk("task/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/task/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateTask = createAsyncThunk("task/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/task/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteTask = createAsyncThunk("task/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/task/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const sessionsSlice = createSlice({
  name: 'task',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.fulfilled, (task, action) => {
     
        task.value.data.rows = action.payload.rows
        task.value.data.count = action.payload.count
        task.value.status = action.payload.message
        console.log('Updated task:', task.value.data.rows);

        
      })
      .addCase(getTasks.pending, (task) => {
        task.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getTasks.rejected, (task) => {
        task.status = "Failed to  fetch data..."
      })
      .addCase(updateTask.fulfilled, (task, action) => {
        console.log(action.payload.message, "action.payload.message")
        task.value.status = action.payload.message
        // find the index of the task with the updated id
        // const index = task.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the task at the found index with the updated task
        //   task.value.data[index] = action.payload;
        // }
      })
      .addCase(updateTask.pending, (task) => {
        task.status = "Updating task. Please wait a moment...";
      })
      .addCase(updateTask.rejected, (task) => {
        task.status = "Failed to update task...";
      })
      .addCase(deleteTask.fulfilled, (task, action) => {
        // // filter out the task with the deleted id
        // task.value.data = task.value.data.filter(item => item.id !== action.payload.id);
        task.value.status = action.payload.message
      })
      .addCase(deleteTask.pending, (task) => {
        task.status = "Deleting task. Please wait a moment...";
      })
      .addCase(deleteTask.rejected, (task) => {
        task.status = "Failed to delete task...";
      })
      .addCase(addTask.fulfilled, (task, action) => {
        // task.value.data.push(action.payload); // add the new task to the list
     })
     .addCase(addTask.pending, (task) => {
        task.status = "Adding task. Please wait a moment...";
     })
     .addCase(addTask.rejected, (task) => {
        task.status = "Failed to add task...";
     });
  }
})

export default sessionsSlice.reducer
