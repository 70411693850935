import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const fetchSettings = createAsyncThunk("settings/fetch", async (roleId) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/setting`, {
    // const response = await fetch(`${process.env.REACT_APP_URL}api/role/fetchSingleRole/:roleId`, {

    // const response = await fetch(`${process.env.REACT_APP_URL}api/role/fetchSingleRole/4`, {

    headers: createHeaders()
  });
  const json = await response.json();
  return json;
});

export const getOpenSetting = createAsyncThunk("settings/getOpenSetting", async (code) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/open/setting/${code}`);
  const json = await response.json();
  return json;
});


// Update a setting on the server
// export const updateSetting = createAsyncThunk("settings/update", async (data) => {
//   const response = await fetch(`${process.env.REACT_APP_URL}api/role/updatesettings`, {
//     method: 'PUT',
//     headers: createHeaders(),
//     body: JSON.stringify({ ...data })
//   });
//   const json = await response.json();
//   return json;
// });

// Update a setting on the server
export const updateSetting = createAsyncThunk("settings/update", async (settings) => {
    console.log(settings, "IN STORE")
    if(!settings.profile) settings.profile = ""
    const response = await fetch(`${process.env.REACT_APP_URL}admin/api/setting`, {
    method: 'PUT',
    headers: createHeaders(),
    body: JSON.stringify(settings)
  });
  const json = await response.json();
  return json;
});


export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    value: {},
    businessSettings: {}
    
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.fulfilled, (state, action) => {
        // state.value = action.payload;
        state.value = {
          ...action.payload,
          ...action.payload.config
        }
      })
      // .addCase(updateSetting.fulfilled, (state, action) => {
      //   // find the index of the setting with the updated id
      //   const index = state.value.findIndex(item => item.id === action.payload.id);
      //   if (index !== -1) {
      //     // replace the setting at the found index with the updated setting
      //     state.value[index] = action.payload;
      //   }
      // });
      .addCase(updateSetting.fulfilled, (state, action) => {
        // state.value = action.payload;
      })
      .addCase(getOpenSetting.fulfilled, (state, action) => {
        // state.value.openBusinessSettings = action.payload;
        state.businessSettings = action.payload;
      });
      
  },
})

export default settingsSlice.reducer
