// testing
import { React, useState } from 'react'
import SwitchField from '../fields/SwitchField'
import InputField from '../fields/InputField'
import TextField from '../fields/TextField'
import Upload from '../upload'
import DropdownSelect from '../../components/select'
import Datepicker from "react-tailwindcss-datepicker";
import moment from 'moment';
import Select from 'react-select'
import { useForm } from 'react-hook-form'


export const Form = (props) => {
  const { fields, formData, onChange, mainDivCss, register, errors  } = props
  console.log(fields, "FIELDS")
  const [value, setValue] = useState({
    startDate: null,
    endDate: null
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  

  const handleOptionChange = (fieldName, selected) => {
    setSelectedOptions(selected);
    const newFormData = { ...formData, [fieldName]: selected };
    onChange(newFormData);
  };

  const handleValueChange = (isSingle, fieldName, newValue) => {
    setValue(newValue);
    const newFormData = { ...formData, [fieldName]: isSingle ? newValue.startDate : newValue };
    onChange(newFormData);
  }

  const handleFieldChange = (fieldName, value, type) => {
    // If the field is of type 'number', parse the value and ensure it's an integer
    if (type === "number") {
      const intValue = parseInt(value, 10);
      // If the parsed value is not a number or is not an integer, do not update the form data
      if (isNaN(intValue)) {
        return;
      }
      value = intValue;
    }

    const newFormData = { ...formData, [fieldName]: value };
    onChange(newFormData);
  };



  return (
    
    <div className={`${mainDivCss} m-2`}>
      {fields.map((field) => {
        let renderedField;
        console.log(field.type, "field.type")
        switch (field.type) {
          case "switch":
            renderedField = (
              <SwitchField
                key={field.id}
                id={field.id}
                label={field.label}
                desc={field.desc}
                checked={formData[field.name] || false}
                onChange={(e) => handleFieldChange(field.name, e.target.checked)}
              />
            );
            break;
          case "text":
          case "mail":
          case "number":
          case "password":
            renderedField = (
              <InputField
                key={field.id}
                label={field.label}
                id={field.id}
                name={field.name}
                type={field.type}
                placeholder={field.placeholder}
                value={formData[field.name] || ''}
                onChange={(e) => handleFieldChange(field.name, e.target.value)}
                register={register||null}
                errors={errors||null}
                required={field.required||false}
                ref={field.ref}
              />
            );
            break;
          case "textarea":
            renderedField = (
              <TextField
                key={field.id}
                label={field.label}
                id={field.id}
                placeholder={field.placeholder}
                columns={field.columns}
                rows={field.rows}
                value={formData[field.name] || ''}
                onChange={(e) => handleFieldChange(field.name, e.target.value)}
              />
            );
            break;
          case "upload":
            renderedField = (
              <Upload
                title={field.name}
                prevData={formData[field.name]}
                placeholder={field.placeholder}
                onChange={(base64Value) => handleFieldChange(field.name, base64Value, null)}
              />
            );
            break;
          case "dropdown":

            renderedField = (
              <DropdownSelect
                list={field.list}
                label={field.label}
                placeHolder={field.placeHolder}
                name={field.name}
                toSelect={field.toSelect}
                selectedvalue={field.toShow ? formData[field.selectedvalue]?.[field.toShow] : (formData[field.selectedvalue]?.name ? formData[field.selectedvalue]?.name : formData[field.selectedvalue])}
                onChange={(value) => handleFieldChange(field.id, value)}
                toShow={field.toShow}
                selectedObject={formData[field.selectedvalue]}
              />
            );
            break;
          case "datetime":
            const value = moment(formData[field.name]).format('DD/MM/YYYY')
            renderedField = (
              <div>
                <label
                  htmlFor={field.id}
                  className="ml-3  text-sm font-bold text-navy-700 dark:text-white"
                >
                  {field.label}
                </label>
                <Datepicker
                  displayFormat={"DD/MM/YYYY"}
                  inputClassName="placeholder-gray-800 w-[100%] p-2 rounded-xl"
                  toggleClassName="text-navy-700 rounded-xl p-3"
                  primaryColor={"lime"}
                  containerClassName={"border-2 border-grey-400 rounded-xl flex mt-2"}
                  value={value}
                  asSingle={field.isSingle}
                  useRange={field.isRange}
                  placeholder={value ? value : formData["placeholder"]}
                  onChange={(e) => {
                    handleValueChange(field.isSingle, field.id, e)
                  }}
                />
              </div>
            );
            break;
          case "dateandtime":
            const formattedDate = formData[field.name] ? moment(formData[field.name]).format("YYYY-MM-DDTHH:mm") : "";
            renderedField = (
              <div className='flex flex-col'>
                <label
                  htmlFor={field.id}
                  className="ml-3 mb-2 text-sm font-bold text-navy-700 dark:text-white"
                >
                  {field.label}
                </label>
                <input
                  type="datetime-local"
                  class="appearance-none border border-grey-500 focus:border-brand-500 focus:outline-none focus:ring-brand-500 leading-tight px-4 py-3 rounded-xl "
                  value={formattedDate}
                  onChange={(e) => handleFieldChange(field.name, e.target.value)}
                />
              </div>
            );
            break;
          case "multiselect":
            function getValueByKeyArray(obj, keysArray) {
              if(!obj || !keysArray) return {}
              let value = obj;
              for (const key of keysArray) {
                if (value.hasOwnProperty(key)) {
                  value = value[key];
                } else {
                  return undefined; // Key not found
                }
              }
              return value;
            }
            const mapOptionsToSelectOptions = (option) => ({
              value: getValueByKeyArray(option, field.toSelect), // Use the 'id' key for selection
              label: option.name, // Display the 'name' key in the dropdown
            });
            const selectOptions = field.options.map(mapOptionsToSelectOptions);
            renderedField = (

              <div className='flex flex-col'>
                <label
                  htmlFor={field.id}
                  className="ml-3 mb-2 text-sm font-bold text-navy-700 dark:text-white"
                >
                  {field.label}
                </label>
                <Select
                 classNames={{
                  input: (state) =>
                    state.isFocused ? 'border-red-600' : 'border-grey-300',
                }}
                  isMulti={true}
                  value={selectedOptions}
                  onChange={(e) => {
                    handleOptionChange(field.name, e)
                  }}
                  options={selectOptions}
                />
              </div>

            );
            break;
          default:
            renderedField = null;
        }

        return (
          <div key={field.id} className='my-5'>
            {renderedField}
          </div>
        );
      })}
    </div>
    

  )
}

export default Form