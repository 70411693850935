/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import logo from "../../assets/img/logo.png"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes.js";
import { fetchSettings } from "../../redux/actions/admin/setting";

const Sidebar = ({ open, onClose }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    console.log("get'ed settings");
    dispatch(fetchSettings())
  }, [dispatch])
  const settings = useSelector((settings) => settings.setting.value);
  return (
    <div
      className={` sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${open ? "translate-x-0" : "-translate-x-96"
        }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex flex-col items-center`}>
        <img
          src={settings.profile ? `${process.env.REACT_APP_URL + "images" + settings.profile}` : logo}
          className="mb-3 rounded-xl w-20"
          alt=""
        />
        <div className="mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          {settings.name ? (settings.name.length > 12 ? `${settings.name.slice(0, 11)}...` : settings.name) : "MAKTAB"}
        </div>


      </div>
      <div className="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 280px)' }}>
        <Links routes={routes} />
      </ul>


      {/* Free Horizon Card */}
      {/* <div className="flex justify-center">
        <SidebarCard />
      </div> */}

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
