
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getClasses = createAsyncThunk("classRoom/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/classRoom?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getSubjectsForClass = createAsyncThunk("classRoomSubject/get", async (classId) => {
console.log(classId, "classId")
let response = await fetch(`${process.env.REACT_APP_URL}admin/api/classRoom/subjects/${classId}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getStudentsForClass = createAsyncThunk("classRoomStudent/get", async (classId) => {
console.log(classId, "classId")
let response = await fetch(`${process.env.REACT_APP_URL}admin/api/classRoom/students/${classId}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});


export const addClass = createAsyncThunk("classRoom/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/classRoom/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateClass = createAsyncThunk("classRoom/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/classRoom/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteClass = createAsyncThunk("classRoom/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/classRoom/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const classesSlice = createSlice({
  name: 'classRoom',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      classSubjects: [],
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClasses.fulfilled, (classRoom, action) => {
     
        classRoom.value.data.rows = action.payload.rows
        classRoom.value.data.count = action.payload.count
        classRoom.value.status = action.payload.message
        console.log('Updated classRoom:', classRoom.value.data.rows);
      })
      .addCase(getClasses.pending, (classRoom) => {
        classRoom.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getSubjectsForClass.fulfilled, (classRoom, action) => {
        classRoom.value.classSubjects = action.payload.length ? action.payload : []
      })
      .addCase(getStudentsForClass.fulfilled, (classRoom, action) => {
        classRoom.value.classStudents = action.payload.length ? action.payload : []
      })
      .addCase(getClasses.rejected, (classRoom) => {
        classRoom.status = "Failed to  fetch data..."
      })
      .addCase(updateClass.fulfilled, (classRoom, action) => {
        console.log(action.payload.message, "action.payload.message")
        classRoom.value.status = action.payload.message
        // find the index of the classRoom with the updated id
        // const index = classRoom.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the classRoom at the found index with the updated classRoom
        //   classRoom.value.data[index] = action.payload;
        // }
      })
      .addCase(updateClass.pending, (classRoom) => {
        classRoom.status = "Updating classRoom. Please wait a moment...";
      })
      .addCase(updateClass.rejected, (classRoom) => {
        classRoom.status = "Failed to update classRoom...";
      })
      .addCase(deleteClass.fulfilled, (classRoom, action) => {
        // // filter out the classRoom with the deleted id
        // classRoom.value.data = classRoom.value.data.filter(item => item.id !== action.payload.id);
        classRoom.value.status = action.payload.message
      })
      .addCase(deleteClass.pending, (classRoom) => {
        classRoom.status = "Deleting classRoom. Please wait a moment...";
      })
      .addCase(deleteClass.rejected, (classRoom) => {
        classRoom.status = "Failed to delete classRoom...";
      })
      .addCase(addClass.fulfilled, (classRoom, action) => {
        // classRoom.value.data.push(action.payload); // add the new classRoom to the list
     })
     .addCase(addClass.pending, (classRoom) => {
        classRoom.status = "Adding classRoom. Please wait a moment...";
     })
     .addCase(addClass.rejected, (classRoom) => {
        classRoom.status = "Failed to add classRoom...";
     });
  }
})

export default classesSlice.reducer
