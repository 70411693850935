import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const fetchPermissions = createAsyncThunk("permissions/fetch", async (roleId) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/role/fetchSingleRole/${roleId}`, {
    // const response = await fetch(`${process.env.REACT_APP_URL}api/role/fetchSingleRole/:roleId`, {

    // const response = await fetch(`${process.env.REACT_APP_URL}api/role/fetchSingleRole/4`, {

    headers: createHeaders()
  });
  const json = await response.json();
  return json;
});


// Update a permission on the server
// export const updatePermission = createAsyncThunk("permissions/update", async (data) => {
//   const response = await fetch(`${process.env.REACT_APP_URL}api/role/updatepermissions`, {
//     method: 'PUT',
//     headers: createHeaders(),
//     body: JSON.stringify({ ...data })
//   });
//   const json = await response.json();
//   return json;
// });

// Update a permission on the server
export const updatePermission = createAsyncThunk("permissions/update", async (permissions) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/role/permission`, {
    method: 'PUT',
    headers: createHeaders(),
    body: JSON.stringify(permissions)
  });
  const json = await response.json();
  return json;
});


export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    value: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.value = action.payload;
      })
      // .addCase(updatePermission.fulfilled, (state, action) => {
      //   // find the index of the permission with the updated id
      //   const index = state.value.findIndex(item => item.id === action.payload.id);
      //   if (index !== -1) {
      //     // replace the permission at the found index with the updated permission
      //     state.value[index] = action.payload;
      //   }
      // });
      .addCase(updatePermission.fulfilled, (state, action) => {
        // state.value = action.payload;
      })
      
  },
})

export default permissionsSlice.reducer
