import { createSlice } from '@reduxjs/toolkit'

export const centersSlice = createSlice({
  name: 'centers',
  initialState: {
    value: [
      {name: "center1",totalMarkaz:10, status: true, desc: "SOME MORE DESC"},
      {name: "center2",totalMarkaz:8, status: true, desc: "SOME MORE DESC"},
      {name: "center3",totalMarkaz:4, status: true, desc: "SOME MORE DESC"},
      {name: "center4",totalMarkaz:6, status: true, desc: "SOME MORE DESC"},
      {name: "center5",totalMarkaz:9, status: true, desc: "SOME MORE DESC"},
    ]
  },
  reducers: {
    addCenter: (center) => {

      center.value.push({name: "center", totalMarkaz:9, status: false, desc: "DESC"})
    }
  },
})

// Action creators are generated for each case reducer function
export const { addCenter } = centersSlice.actions

export default centersSlice.reducer