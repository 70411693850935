import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoEnter } from "react-icons/io5";
import { getNotifications } from '../../redux/actions/home';
import { useDispatch, useSelector } from 'react-redux';

function Notifications() {
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 10,
        page: 1
    });
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        dispatch(getNotifications(filter));
    }, [dispatch, filter]);

    const notifications = useSelector((state) => state.home.value.notifications.rows);
    
    return (
        <div className=' bg-lightPrimary h-screen p-4'>
            <div className='p-2 bg-white rounded-xl '>
                {
                    notifications?.length === 0 &&
                    <p className='text-center mt-5 mb-5'> No notifications found </p>
                }
                {
                    notifications?.map((notification, index) => {
                        return (
                            <ul
                                key={notification.id}
                                className='h-3/12 my-5 border border-brand-500 shadow-gray-200 shadow-xl w-full p-4 rounded-xl flex items-center justify-between'
                            >
                                <li className='font-poppins'> {index + 1 + ". " + notification?.message} </li>
                            </ul>
                        )
                    })
                }
                {isLoading && <p className="text-center">Loading...</p>}
            </div>
        </div>
    )
}

export default Notifications;
