
// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import { createHeaders } from '../headers';


// export const getTaskTypes = createAsyncThunk("taskType/get", async (query) => {
//   console.log(query)

//   let filteredQuery = Object.fromEntries(
//     Object.entries(query).filter(([key, value]) => value != null && value !== '')
// );
// let params = new URLSearchParams(filteredQuery);

// let response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType?${params.toString()}`, {
//   headers: createHeaders()
// });
// let json = await response.json();
// return json;
// });

// export const addTaskLog = createAsyncThunk("taskLog/add", async (data) => {
//   const response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/addTaskLog`, {
//     method: 'POST',
//     headers: createHeaders(),
//     body: JSON.stringify(data),
//   });
//   const json = await response.json();
//   return json;
// });

// export const updateTaskLog = createAsyncThunk("taskLog/update", async (data) => {
//   console.log(data, "ID and DATA for update");
//   let response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/updateTaskLog`, {
//     method: 'PUT', // or 'PATCH' depending on your API
//     headers: createHeaders(),
//     body: JSON.stringify({ ...data }) // Include the id in the request body
//   });
//   let json = await response.json();
//   return json;
// });

// export const deleteTaskLog = createAsyncThunk("TaskLog/delete", async (userId) => {
//   console.log(userId, "ID for delete");
//   let response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/deleteTaskLog/${userId}`, {
//     method: 'DELETE',
//     headers: createHeaders(),
//   });
//   let json = await response.json();
//   return json;
// });

// export const addTaskType = createAsyncThunk("taskType/add", async (data) => {
//   const response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/`, {
//     method: 'POST',
//     headers: createHeaders(),
//     body: JSON.stringify(data),
//   });
//   const json = await response.json();
//   return json;
// });



// export const updateTaskType = createAsyncThunk("taskType/update", async (data) => {
//   console.log(data, "ID and DATA for update");
//   let response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/`, {
//     method: 'PUT', // or 'PATCH' depending on your API
//     headers: createHeaders(),
//     body: JSON.stringify({ ...data }) // Include the id in the request body
//   });
//   let json = await response.json();
//   return json;
// });


// export const deleteTaskType = createAsyncThunk("taskType/delete", async (id) => {
//   console.log(id, "ID for delete");
//   let response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/${id}`, {
//     method: 'DELETE',
//     headers: createHeaders(),
//   });
//   let json = await response.json();
//   return json;
// });

// export const taskTypeSlice  = createSlice({
//   name: 'taskType',
//   initialState: {
  
//     value: {
//       isError: false,
//       isLoading: false,
//       status: 'default',
//       data: {
//         count: 0,
//         rows: []
//       }
//     }
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(getTaskTypes.fulfilled, (taskType, action) => {
     
//         taskType.value.data.rows = action.payload.rows
//         taskType.value.data.count = action.payload.count
//         taskType.value.status = action.payload.message
//         console.log('Updated taskType:', taskType.value.data.rows);

        
//       })
//       .addCase(getTaskTypes.pending, (taskType) => {
//         taskType.status = "Fetching todos. Please wait a moment..."
//       })
//       .addCase(getTaskTypes.rejected, (taskType) => {
//         taskType.status = "Failed to  fetch data..."
//       })
//       .addCase(updateTaskType.fulfilled, (taskType, action) => {
//         console.log(action.payload.message, "action.payload.message")
//         taskType.value.status = action.payload.message
//         // find the index of the taskType with the updated id
//         // const index = taskType.value.data.findIndex(item => item.id === action.payload.id);
//         // if (index !== -1) {
//         //   // replace the taskType at the found index with the updated taskType
//         //   taskType.value.data[index] = action.payload;
//         // }
//       })
//       .addCase(updateTaskType.pending, (taskType) => {
//         taskType.status = "Updating taskType. Please wait a moment...";
//       })
//       .addCase(updateTaskType.rejected, (taskType) => {
//         taskType.status = "Failed to update taskType...";
//       })
//       .addCase(deleteTaskType.fulfilled, (taskType, action) => {
//         // // filter out the taskType with the deleted id
//         // taskType.value.data = taskType.value.data.filter(item => item.id !== action.payload.id);
//         taskType.value.status = action.payload.message
//       })
//       .addCase(deleteTaskType.pending, (taskType) => {
//         taskType.status = "Deleting taskType. Please wait a moment...";
//       })
//       .addCase(deleteTaskType.rejected, (taskType) => {
//         taskType.status = "Failed to delete taskType...";
//       })
//       .addCase(addTaskType.fulfilled, (taskType, action) => {
//         // taskType.value.data.push(action.payload); // add the new taskType to the list
//      })
//      .addCase(addTaskType.pending, (taskType) => {
//         taskType.status = "Adding taskType. Please wait a moment...";
//      })
//      .addCase(addTaskType.rejected, (taskType) => {
//         taskType.status = "Failed to add taskType...";
//      });
//   }
// })

// export default taskTypeSlice .reducer







import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getTaskTypes = createAsyncThunk("taskType/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addTaskLog = createAsyncThunk("taskLog/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/addTaskLog`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const updateTaskLog = createAsyncThunk("taskLog/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/updateTaskLog`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});

export const deleteTaskLog = createAsyncThunk("TaskLog/delete", async (userId) => {
  console.log(userId, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/deleteTaskLog/${userId}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const addTaskType = createAsyncThunk("taskType/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateTaskType = createAsyncThunk("taskType/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteTaskType = createAsyncThunk("taskType/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}user/api/taskType/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const taskTypeSlice  = createSlice({
  name: 'taskType',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskTypes.fulfilled, (taskType, action) => {
     
        taskType.value.data.rows = action.payload.rows
        taskType.value.data.count = action.payload.count
        taskType.value.status = action.payload.message
        console.log('Updated taskType:', taskType.value.data.rows);

        
      })
      .addCase(getTaskTypes.pending, (taskType) => {
        taskType.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getTaskTypes.rejected, (taskType) => {
        taskType.status = "Failed to  fetch data..."
      })
      .addCase(updateTaskType.fulfilled, (taskType, action) => {
        console.log(action.payload.message, "action.payload.message")
        taskType.value.status = action.payload.message
        // find the index of the taskType with the updated id
        // const index = taskType.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the taskType at the found index with the updated taskType
        //   taskType.value.data[index] = action.payload;
        // }
      })
      .addCase(updateTaskType.pending, (taskType) => {
        taskType.status = "Updating taskType. Please wait a moment...";
      })
      .addCase(updateTaskType.rejected, (taskType) => {
        taskType.status = "Failed to update taskType...";
      })
      .addCase(deleteTaskType.fulfilled, (taskType, action) => {
        // // filter out the taskType with the deleted id
        // taskType.value.data = taskType.value.data.filter(item => item.id !== action.payload.id);
        taskType.value.status = action.payload.message
      })
      .addCase(deleteTaskType.pending, (taskType) => {
        taskType.status = "Deleting taskType. Please wait a moment...";
      })
      .addCase(deleteTaskType.rejected, (taskType) => {
        taskType.status = "Failed to delete taskType...";
      })
      .addCase(addTaskType.fulfilled, (taskType, action) => {
        // taskType.value.data.push(action.payload); // add the new taskType to the list
     })
     .addCase(addTaskType.pending, (taskType) => {
        taskType.status = "Adding taskType. Please wait a moment...";
     })
     .addCase(addTaskType.rejected, (taskType) => {
        taskType.status = "Failed to add taskType...";
     });
  }
})

export default taskTypeSlice .reducer
