import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { validateToken } from "../../redux/actions/auth"
import Permission from "views/admin/permission";
import Course from "views/admin/single-course";
import SingleSubject from "views/admin/single-subject";
import CrudAdmission from "views/admin/crud-admission";
import SinglePeriod  from "views/admin/single-period";
// import { fetchSettings } from "../../redux/actions/admin/setting"


// import PlanDetails from "views/admin/plan-details";
// import ModuleDetails from "views/admin/module-details";

export default function Admin(props) {
  //const msg =gg('nbbn');
  // const access_token = useSelector((auth) => auth.auth.value.access_token);
  // const [authenticated, setAuthenticated] = React.useState(false)
  const dispatch = useDispatch()
  dispatch(validateToken())
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [title, setTitle] = React.useState(null);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

  

  React.useEffect(() => {
    getActiveRoute(routes);
    setTitle(null)
  }, [location.pathname]);

  React.useEffect(() => {

  }, [currentRoute]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        if (routes[i].children) {
          const subRoute = location.pathname.split("/")[3]
          routes[i].subRoute = routes[i].children.find(item => item.path === subRoute);
        }
        setCurrentRoute(routes[i]);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/admin") {
  //       if (prop.isDropdown) {
  //         const subRoute = location.pathname.split("/")[3]
  //         const childComp = prop.children.find(item => item.path === subRoute);

  //         return (
  //           childComp ?
  //             <Route path={`/${prop.path}/${childComp.path}`} element={childComp.component} key={key} />
  //             :
  //             <Route path={`/${prop.path}`} element={prop.component} key={key} />
  //         )
  //       }

  //       return (
  //         <Route path={`/${prop.path}`} element={prop.component} key={key} />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.isDropdown) {
          const subRoute = location.pathname.split("/")[3]
          const childComp = prop.children.find(item => item.path === subRoute);
          return (
            childComp ?
              <Route path={`/${prop.path}/${childComp.path}`} element={childComp.component} key={key} />
              :
              <Route path={`/${prop.path}`} element={prop.component} key={key} />
          )
        }

        // Handle dynamic routes
        if (prop.path.includes(':')) {
          const dynamicPath = prop.path.split(':')
          return (
            <Route path={`/${prop.path + dynamicPath[0]}`} element={prop.component} key={key} />
          );
        }

        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };



  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Maktab"}
              brandText={`${title ? title : window.location.pathname.split("/")[3] && window.location.pathname.split("/")[3].length < 15 ? window.location.pathname.split("/")[3] : window.location.pathname.split("/")[2]}`}
              // brandText={currentRoute.name ? currentRoute.name : "" + (currentRoute.subRoute ? ` / ${currentRoute.subRoute.name}` : `${window.location.pathname.split("/")[2]}`)}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {/* <Route path={`/plan/:id`} element={<PlanDetails />} /> */}
                <Route  path={`/permission/:id`} element={<Permission setTitle={setTitle} />} />
                <Route  path={`/course/:id`} element={<Course  setTitle={setTitle} />} />
                <Route  path={`/admission/:id`} element={<CrudAdmission setTitle={setTitle} />} />
                <Route  path={`/period/:id`} element={<SinglePeriod setTitle={setTitle} />} />
                <Route  path={`/subject/:id`} element={<SingleSubject setTitle={setTitle} />} />

                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}


