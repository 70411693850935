// // import Footer from "components/footer/FooterAuthDefault";
// import authImg from "assets/img/logo.png";
// import { Link, Routes, Route, Navigate } from "react-router-dom";
// import routes from "routes.js";
// import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// import ResetPassword from "../../views/auth/forget-password/ResetPassword"
// import CreateNewPassword from "../../views/auth/forget-password/CreateNewPassword"
// import Signup from "views/auth/signup";
// import Login from "../../views/auth/signin"
// import { useSelector } from "react-redux";

// export default function Auth() {
//   const getRoutes = (routes) => {
//     return routes.map((prop, key) => {
//       if (prop.layout === "/auth") {
//         return (
//           <Route path={`/${prop.path}`} element={prop.component} key={key} />
//         );
//       } else {
//         return null;
//       }
//     });
//   };
//   document.documentElement.dir = "ltr";
//   const businessLogo = useSelector((state) => state.setting.businessSettings);
//   console.log("businessLogo --"+JSON.stringify(businessLogo.profile));

//   return (
//     <div className="relative float-right h-full min-h-screen w-full bg-white">
//       {/* <FixedPlugin /> */}

//       <main className="mx-auto min-h-screen">
//         <div className="flex ">
//           <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
//             <div className="mb-auto flex flex-col items-center pl-5 pr-5  lg:mt-10 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
//             <div
//   className="bg-center bg-contain bg-no-repeat justify-center rounded-xl w-[50%] h-[100px] lg:w-[15%] lg:h-[200px] md:w-[40%] md:h-[250px] sm:w-[50%] sm:h-[250px]"
//   // style={{ backgroundImage: `url(${authImg})`,  }}
//   style={{ backgroundImage: `url(${businessLogo.profile ? process.env.REACT_APP_URL + "images" + businessLogo.profile : authImg})`  }}
// />

//               <Routes>
//                 <Route
//                   path="/"
//                   element={<Navigate to="/auth/sign-in" replace />}
//                 />
//                 <Route
//                   path="/sign-in"
//                   element={<Login />}
//                 />
//                 <Route
//                   path="/signup"
//                   element={<Signup />}
//                 />
//                 <Route
//                   path="/forget-password"
//                   element={<ResetPassword />}
//                 />
//                 <Route
//                   path="/reset-password/"
//                   element={<CreateNewPassword />}
//                 />
//               </Routes>
//               <p className='absolute bottom-[30px] text-center mt-5 dark:text-white'> made with ❤️ at <a className='text-blue-500' href="https://www.ifelsetech.com/"> ifelse </a> </p>

//             </div>
//           </div>
//         </div>
//       </main>
//     </div>
//   );
// }


//working
// import React from "react";
// import { Routes, Route, Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import authImg from "assets/img/logo.png";
// import routes from "routes.js";
// import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// import ResetPassword from "../../views/auth/forget-password/ResetPassword";
// import CreateNewPassword from "../../views/auth/forget-password/CreateNewPassword";
// import Signup from "views/auth/signup";
// import Login from "../../views/auth/signin";

// export default function Auth() {
//   const getRoutes = (routes) => {
//     return routes.map((prop, key) => {
//       if (prop.layout === "/auth") {
//         return (
//           <Route path={`/${prop.path}`} element={prop.component} key={key} />
//         );
//       } else {
//         return null;
//       }
//     });
//   };
//   document.documentElement.dir = "ltr";
//   const businessLogo = useSelector((state) => state.setting.businessSettings);
//   console.log("businessLogo --" + JSON.stringify(businessLogo.profile));

//   return (
//     <div className="relative float-right h-full min-h-screen w-full bg-white">
//       <main className="mx-auto min-h-screen">
//         <div className="flex min-h-full w-full justify-center">
//           <div className="flex flex-col items-center lg:w-full lg:max-w-[1013px] lg:px-8 xl:w-full xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
//             <div className="mb-auto flex flex-col items-center pl-5 pr-5 md:pr-0 md:pl-12 lg:mt-10 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
//               {/* <div
//                 className="flex h-[100px] w-[100px] items-center justify-center rounded-xl bg-cover bg-center sm:h-[140px] sm:w-[140px] md:h-[150px] md:w-[150px] lg:h-[150px] lg:w-[150px]"
//                 style={{
//                   backgroundImage: `url(${
//                     businessLogo.profile
//                       ? process.env.REACT_APP_URL +
//                         "images" +
//                         businessLogo.profile
//                       : authImg
//                   })`,
//                 }}
//               /> */}
//               <div className=" flex h-[100px] w-[100px] items-center justify-center rounded-xl bg-cover bg-center sm:h-[140px] sm:w-[140px] md:h-[150px] md:w-[150px] lg:mb-2 lg:h-[150px] lg:w-[150px]  my-2">
//                 <img
//                   src={
//                     businessLogo.profile
//                       ? process.env.REACT_APP_URL +
//                         "images" +
//                         businessLogo.profile
//                       : authImg
//                   }
//                   alt="Business Logo"
//                   className="h-full w-full rounded-xl object-cover"
//                 />
//               </div>

//               <Routes>
//                 <Route
//                   path="/"
//                   element={<Navigate to="/auth/sign-in" replace />}
//                 />
//                 <Route path="/sign-in" element={<Login />} />
//                 <Route path="/signup" element={<Signup />} />
//                 <Route path="/forget-password" element={<ResetPassword />} />
//                 <Route
//                   path="/reset-password/"
//                   element={<CreateNewPassword />}
//                 />
//               </Routes>
//               {/* <p className="absolute bottom-[30px] mt-5 text-center dark:text-white">
//                 {" "}
//                 made with ❤️ at{" "}
//                 <a className="text-blue-500" href="https://www.ifelsetech.com/">
//                   {" "}
//                   ifelse{" "}
//                 </a>{" "}
//               </p> */}
//               <p className="mt-5 pb-8 text-center dark:text-white">
//                 {" "}
//                 made with ❤️ at{" "}
//                 <a className="text-blue-500" href="https://www.ifelsetech.com/">
//                   {" "}
//                   ifelse{" "}
//                 </a>{" "}
//               </p>
//             </div>
//           </div>
//         </div>
//       </main>
//     </div>
//   );
// }







// import React from "react";
// import { Routes, Route, Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import authImg from "assets/img/logo.png";
// import routes from "routes.js";
// import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// import ResetPassword from "../../views/auth/forget-password/ResetPassword";
// import CreateNewPassword from "../../views/auth/forget-password/CreateNewPassword";
// import Signup from "views/auth/signup";
// import Login from "../../views/auth/signin";

// export default function Auth() {
//   const getRoutes = (routes) => {
//     return routes.map((prop, key) => {
//       if (prop.layout === "/auth") {
//         return (
//           <Route path={`/${prop.path}`} element={prop.component} key={key} />
//         );
//       } else {
//         return null;
//       }
//     });
//   };
//   document.documentElement.dir = "ltr";
//   const businessLogo = useSelector((state) => state.setting.businessSettings);
//   console.log("businessLogo --" + JSON.stringify(businessLogo.profile));

//   return (
//     <div className="relative float-right h-full min-h-screen w-full bg-white">
//       <main className="mx-auto min-h-screen">
//         <div className="flex min-h-full w-full justify-center">
//           <div className="flex flex-col items-center lg:w-full lg:max-w-[1013px] lg:px-8 xl:w-full xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
//             <div className="mb-auto flex flex-col items-center pl-5 pr-5 md:pr-0 md:pl-12 lg:mt-10 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
              
//               <div className=" flex h-[100px] w-[100px] items-center justify-center rounded-xl bg-cover bg-center sm:h-[140px] sm:w-[140px] md:h-[150px] md:w-[150px] lg:mb-2 lg:h-[150px] lg:w-[150px]  my-2">
//                 <img
//                   src={
//                     businessLogo.profile
//                       ? process.env.REACT_APP_URL +
//                         "images" +
//                         businessLogo.profile
//                       : authImg
//                   }
//                   alt="Business Logo"
//                   className="h-full w-full rounded-xl object-cover"
//                 />
//               </div>

//               <Routes>
//                 <Route
//                   path="/"
//                   element={<Navigate to="/auth/sign-in" replace />}
//                 />
//                 <Route path="/sign-in" element={<Login />} />
//                 <Route path="/signup" element={<Signup />} />
//                 <Route path="/forget-password" element={<ResetPassword />} />
//                 <Route
//                   path="/reset-password/"
//                   element={<CreateNewPassword />}
//                 />
//               </Routes>
//               {/* <p className="absolute bottom-[30px] mt-5 text-center dark:text-white">
//                 {" "}
//                 made with ❤️ at{" "}
//                 <a className="text-blue-500" href="https://www.ifelsetech.com/">
//                   {" "}
//                   ifelse{" "}
//                 </a>{" "}
//               </p> */}
//               <p className=" py-8 text-center dark:text-white">
//                 {" "}
//                 made with ❤️ at{" "}
//                 <a className="text-blue-500" href="https://www.ifelsetech.com/">
//                   {" "}
//                   ifelse{" "}
//                 </a>{" "}
//               </p>
//             </div>
//           </div>
//         </div>
//       </main>
//     </div>
//   );
// }



// working latest responsive sm device prob
import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import authImg from "assets/img/logo.png";
import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import ResetPassword from "../../views/auth/forget-password/ResetPassword";
import CreateNewPassword from "../../views/auth/forget-password/CreateNewPassword";
import Signup from "views/auth/signup";
import Login from "../../views/auth/signin";
import VerifyUser from "views/auth/signup/VerifyUser";
import TermsAndCondition from "views/auth/signup/TermsAndCondition";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  const businessLogo = useSelector((state) => state.setting.businessSettings);
  // console.log("businessLogo --" + JSON.stringify(businessLogo.profile));

  const location = useLocation();
  const hideImage = location.pathname === "/auth/signup";

  return (
    <div className="relative float-right h-full min-h-screen w-full bg-white">
      <main className="mx-auto min-h-screen flex justify-center items-center">
        <div className="flex flex-col items-center lg:max-w-[1013px] lg:px-8 xl:max-w-[1383px] xl:px-0">
          <div className="mb-auto flex flex-col items-center pl-5 pr-5 md:pr-0 md:pl-12  lg:max-w-[48%] lg:pl-0 xl:max-w-full">
            {!hideImage && <div className="flex flex-col h-[100px] w-[100px] items-center justify-center rounded-xl bg-cover bg-center sm:h-[140px] md:h-[150px] lg:mb-2 lg:h-[150px]  my-2">
              <img
                src={
                  businessLogo.profile
                    ? process.env.REACT_APP_URL +
                      "images" +
                      businessLogo.profile
                    : authImg
                }
                alt="Business Logo"
                className="h-full w-full rounded-xl object-cover"
              />
              <p className="mt-3 text-4xl font-bold text-center text-brand-500 dark:text-white w-[300px]"> {businessLogo.name} </p>
            </div>}
            {hideImage && <div className="flex items-center w[50%]">
              <img
                src={
                  businessLogo.profile
                    ? process.env.REACT_APP_URL +
                      "images" +
                      businessLogo.profile
                    : authImg
                }
                alt="Business Logo"
                className=" object-cover  h-[50px] w-[50px] items-center justify-center rounded-xl bg-cover bg-center sm:h-[40px] sm:w-[40px] md:h-[50px] md:w-[50px] lg:mb-2 lg:h-[50px] lg:w-[50px] my-2"
              />
              {/* <h1 className="ml-4 text-xl font-medium">REGISTER NEW BUSINESS</h1> */}
              <h1 className="ml-4 text-xl font-medium">Register New 
                <span className="text-brand-400"> Maktab </span>
              </h1>
            </div>
            }

            <Routes>
              <Route
                path="/"
                element={<Navigate to="/auth/sign-in" replace />}
              />
              <Route path="/sign-in" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/user-verification" element={<VerifyUser />} />
              <Route path="/forget-password" element={<ResetPassword />} />
              <Route path="/terms-and-condition" element={<TermsAndCondition />} />
              <Route
                path="/reset-password/"
                element={<CreateNewPassword />}
              />
            </Routes>
            <p className=" py-8 text-center dark:text-white">
              {" "}
              made with ❤️ at{" "}
              <a className="text-blue-500" href="https://www.ifelsetech.com/">
                {" "}
                ifelse{" "}
              </a>{" "}
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}



