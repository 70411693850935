
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getMaterials = createAsyncThunk("material/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/material?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getSingleMaterial = createAsyncThunk("singlematerial/get", async (id) => {
  console.log(id)

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/material/${id}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getAllMaterials = createAsyncThunk("allmaterial/get", async () => {

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/material/all`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getMaterial = createAsyncThunk("single-material/get", async (id) => {

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/material/${id}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addMaterial = createAsyncThunk("material/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/material/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const approveMaterial = createAsyncThunk("material/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/material/approve`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const postOpenMaterial = createAsyncThunk("material/add", async (query) => {
  const { code, formData } = query
  console.log(formData, "formData")
  const response = await fetch(`${process.env.REACT_APP_URL}admin/open/material/${code}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });
  const json = await response.json();
  return json;
});



export const updateMaterial = createAsyncThunk("material/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/material/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteMaterial = createAsyncThunk("material/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/material/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const materialsSlice = createSlice({
  name: 'materiales',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMaterials.fulfilled, (material, action) => {
     
        material.value.data.rows = action.payload.rows
        material.value.data.count = action.payload.count
        material.value.status = action.payload.message
        console.log('Updated material:', material.value.data.rows);

        
      })
      .addCase(getSingleMaterial.fulfilled, (material, action) => {
     
        material.value.singleMaterial = action.payload
        
      })
      .addCase(getAllMaterials.fulfilled, (material, action) => {
        material.value.allMaterials = action.payload
      })
      .addCase(getMaterials.pending, (material) => {
        material.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getMaterials.rejected, (material) => {
        material.status = "Failed to  fetch data..."
      })
      .addCase(updateMaterial.fulfilled, (material, action) => {
        console.log(action.payload.message, "action.payload.message")
        material.value.status = action.payload.message
        // find the index of the material with the updated id
        // const index = material.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the material at the found index with the updated material
        //   material.value.data[index] = action.payload;
        // }
      })
      .addCase(updateMaterial.pending, (material) => {
        material.status = "Updating material. Please wait a moment...";
      })
      .addCase(updateMaterial.rejected, (material) => {
        material.status = "Failed to update material...";
      })
      .addCase(deleteMaterial.fulfilled, (material, action) => {
        // // filter out the material with the deleted id
        // material.value.data = material.value.data.filter(item => item.id !== action.payload.id);
        material.value.status = action.payload.message
      })
      .addCase(deleteMaterial.pending, (material) => {
        material.status = "Deleting material. Please wait a moment...";
      })
      .addCase(deleteMaterial.rejected, (material) => {
        material.status = "Failed to delete material...";
      })
      .addCase(addMaterial.fulfilled, (material, action) => {
        // material.value.data.push(action.payload); // add the new material to the list
     })
     .addCase(addMaterial.pending, (material) => {
        material.status = "Adding material. Please wait a moment...";
     })
     .addCase(addMaterial.rejected, (material) => {
        material.status = "Failed to add material...";
     });
  }
})

export default materialsSlice.reducer
