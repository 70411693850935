


// import React, { useState,  useRef, useEffect } from "react";
// import Form from "components/form";
// import { useNavigate } from "react-router-dom";
// import {addBusiness} from '../../../redux/actions/business'
// import {sendUserVerificationLink} from '../../../redux/actions/auth'
// import { useDispatch, useSelector } from "react-redux";
// import mailSentImg from '../../../assets/img/auth/1.png'
// import Checkbox from "components/checkbox";
// import { useForm } from "react-hook-form";
// import {yupResolver} from '@hookform/resolvers/yup'
// import * as yup from 'yup'




// const Signup = () => {
//     const [formData, setFormData] = useState({});
//     const [validationErrors, setValidationErrors] = useState({});
//     const [stepNumber, setStepNumber] = useState(1);

//     const schema = yup.object().shape({
//         name: yup.string().nullable(false).required('Name is required'),
//         email: yup.string().nullable(false).email('Invalid email').required('Email is required'),
//         country: yup.string().nullable(false).required('Country is required'),
//         referralCode: yup.string().nullable(false),
//         // mobile: yup.number().nullable(false).required('Mobile number is required'),
//         mobile: yup
//         .number() // Ensures it's a number
//         .typeError('Mobile number must be a number') // Error message if it's not a number
//         .nullable(false) // Ensures it's not nullable
//         .required('Mobile number is required')
//     });
    
//     const inputRefs = {
//         name: useRef(null),
//         email: useRef(null),
//         country: useRef(null),
//         referralCode: useRef(null),
//         mobile: useRef(null)
//     };


   
//     // useEffect(() => {
//     //     if (inputRefs.name.current) {
//     //         inputRefs.name.current.focus(); 
//     //     }
//     // }, []);
    
//     // useEffect(() => {
//     //     if (Object.keys(validationErrors).length) {
//     //         console.log("validationErrors useeffect inside:"+JSON.stringify(validationErrors));
//     //         schema.validate(formData, {abortEarly:false})
            
//     //     }
//     // }, [formData,validationErrors]);
    

//     const navigate = useNavigate();
//     const dispatch = useDispatch();

    

    
//     const signupFields = [
//         {
//             id: "name",
//             name: "name",
//             label: "Business's Name",
//             placeholder: "Enter Business's Name",
//             type: "text",
//             ref: inputRefs.name // Assign ref to the field
//         },
//         {
//             id: "email",
//             name: "email",
//             label: "Email",
//             placeholder: "Enter Email",
//             type: "text",
//             ref: inputRefs.email // Assign ref to the field
//         },
//         {
//             id: "country",
//             name: "country",
//             label: "Country's Name",
//             placeholder: "Enter Country's Name",
//             type: "text",
//             ref: inputRefs.country // Assign ref to the field
//         },
//         {
//             id: "referralCode",
//             name: "referralCode",
//             label: "Referral-Code",
//             placeholder: "Enter Referral-Code",
//             type: "text",
//             ref: inputRefs.referralCode // Assign ref to the field
//         },
//         {
//             id: "mobile",
//             name: "mobile",
//             label: "Phone number",
//             placeholder: "Enter Phone number",
//             type: "text",
//             ref: inputRefs.mobile // Assign ref to the field
//         },
//     ];
    

    

//     const func= async(e)=>{
//         e.preventDefault()
//         try {
//             await schema.validate(formData, {abortEarly:false})
//             console.log("form submitted:", formData);
//             setValidationErrors({})
//             const action = await dispatch(addBusiness(formData));
//             console.log("action.payload:"+JSON.stringify(action.payload.success));

//             if(action.payload.success){
//                 setStepNumber(prevStepNumber => prevStepNumber + 1);
//                 const action = await dispatch(sendUserVerificationLink(formData.email));
//             }
      
            

//         } catch (error) {
//             const newErrors = {}

//             error.inner.forEach((err)=>{
//                 newErrors[err.path] = err.message
//             })
            
//             setValidationErrors(newErrors )

//             if (error.inner.length > 0) {
//                 const firstErrorField = error.inner[0].path;
//                 if (inputRefs[firstErrorField]) {
//                     inputRefs[firstErrorField].current.focus();
//                 }
//             }
//         }

    
      
//     }

    

//     const handleCheckBox=(e)=>{
//         console.log(e.target.name);
//         console.log(e.target.checked);
//     }
    
//     return (
//         // <div className="min-h-[80%] max-w-[60%] rounded-xl border-2 shadow-md">
//         <div className="min-h-[80%] max-w-[552px] rounded-xl border-2 shadow-md">
//             <div className="flex justify-center  border-b-2 py-3">
//                 <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white ">
//                     1
//                 </button>
//                 <div className="mx-3 flex w-40 items-center justify-center">
//                     <hr className={`w-full border-2 ${stepNumber>=1?'border-brand-500':''}`} />
//                     <hr className={`w-full border-2 ${stepNumber>1?'border-brand-500':''}`} />
//                 </div>
//                 <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
//                     2
//                 </button>
//                 <div className="mx-3 flex w-40 items-center justify-center">
//                     <hr className={`w-full border-2 ${stepNumber>2?'border-brand-500':''}`} />
//                     <hr className={`w-full border-2 ${stepNumber>2?'border-brand-500':''}`} />
//                 </div>
//                 <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
//                     3
//                 </button>
//             </div>
//             {/* 1 */}
//             {stepNumber===1 && (<div className="p-6">
//                 <h1 className="mb-2 text-xl font-medium text-gray-900">
//                     Basic Information
//                 </h1>
//                 <p className="text-gray-800">
//                     Please provide your name, email address, and any other relevant
//                     personal details to complete the first step of the signup process.
//                 </p>
//                 <form onSubmit={func}>
//                 {/* <form onSubmit={handleSubmit(submitForm)}> */}
//                 <Form
//                     fields={signupFields}
//                     formData={formData}
//                     onChange={(newFormData) => setFormData(newFormData)}
//                     mainDivCss={"grid grid-cols-2 gap-x-8 mb-4"}
//                     errors={validationErrors}
//                 />
//                 {/* <div className="flex mb-4">
//                     <Checkbox required={true}  onClick={handleCheckBox}/>
//                     <p className="ml-2 " >By clicking Signup, you agree to our  <span className="text-blue-700 hover:cursor-pointer" onClick={()=>navigate('/auth/terms-and-condition')}>Terms and Conditions.</span></p>
//                 </div>
//                 <div className="flex mb-4">
//                     <Checkbox required={true}/>
//                     <p className="ml-2 ">Send me the updates and marketing emails, You can opt-out at any time from your profile settings.</p>
//                 </div> */}
//                 <button className="h-10 w-full rounded-xl bg-brand-500 text-base font-medium text-white my-4" type="submit">
//                     signup
//                 </button>
//                 </form>
               
//                 <div className="flex justify-center">
//                     <h1 className="my-2">
//                         Already have an account?
//                         <span
//                             className="font-bold text-brand-500 hover:cursor-pointer"
//                             onClick={(e) => {
//                                 navigate("/auth/sign-in");
//                             }}
//                         >
//                             {" "}
//                             Signin
//                         </span>
//                     </h1>
//                 </div>
//             </div>)}
//             {/* 2 */}
//             {stepNumber===2 &&(<div className="p-6">
//                 <h1 className="mb-2 text-xl font-medium text-gray-900">
//                 Verify your email
//                 </h1>
//                 {console.log("formData"+JSON.stringify(formData))}
//                 <p className="text-gray-800">
//                 An email has been dispatched to your registered email address. Please check your inbox for an email sent by Makthab and proceed to click on the provided link to complete the email verification process.
//                 </p>
//                 <div className="flex justify-center">
//                     <img src={mailSentImg} alt="" className="h-[350px] " />
//                 </div>
//             </div>)}
//         </div>
//     );
// };

// export default Signup;





import React, { useState,  useRef, useEffect } from "react";
import Form from "components/form";
import { useNavigate } from "react-router-dom";
import {addBusiness} from '../../../redux/actions/business'
import {sendUserVerificationLink} from '../../../redux/actions/auth'
import { useDispatch, useSelector } from "react-redux";
import mailSentImg from '../../../assets/img/auth/1.png'
import Checkbox from "components/checkbox";
import { useForm } from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import toast, { Toaster } from 'react-hot-toast';




const Signup = () => {
    const [formData, setFormData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [stepNumber, setStepNumber] = useState(1);

    const schema = yup.object().shape({
        name: yup.string().nullable(false).required('Name is required'),
        email: yup.string().nullable(false).email('Invalid email').required('Email is required'),
        referralCode: yup.string().nullable(false),
        // mobile: yup.number().nullable(false).required('Mobile number is required'),
        mobile: yup
        .number() // Ensures it's a number
        .typeError('Mobile number must be a number') // Error message if it's not a number
        .nullable(false) // Ensures it's not nullable
        .required('Mobile number is required')
    });
    
    const inputRefs = {
        name: useRef(null),
        email: useRef(null),
        referralCode: useRef(null),
        mobile: useRef(null)
    };

    
    useEffect(() => {
        const validate = async () => {
          const result = await validateFormData(formData);
          if (!result.isValid) {
            setValidationErrors(result.errors);
          } else {
            setValidationErrors({});
          }
        };
        if(Object.keys(validationErrors).length){
            validate();
        }
      }, [formData]);
    

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const notifySuccess = (message) => toast.success(message, {
        style: {
          padding: '35px',
          color: '#a0ca00',
        },
        iconTheme: {
          primary: '#a0ca00',
          secondary: '#222c25',
        }
      })
      const notifyError = (message) => toast.error(message, {
        style: {
          border: '1px solid #fff',
          padding: '35px',
          color: 'red',
        },
        iconTheme: {
          primary: 'red',
          secondary: '#fff',
        }
      })

    
    const signupFields = [
        {
            id: "name",
            name: "name",
            label: "Maktab Name",
            placeholder: "Enter Maktab Name",
            type: "text",
            ref: inputRefs.name // Assign ref to the field
        },
        {
            id: "email",
            name: "email",
            label: "Email",
            placeholder: "Enter Email",
            type: "text",
            ref: inputRefs.email // Assign ref to the field
          },
          {
              id: "mobile",
              name: "mobile",
              label: "Phone number",
              placeholder: "Enter Phone number",
              type: "text",
              ref: inputRefs.mobile // Assign ref to the field
          },
        {
            id: "referralCode",
            name: "referralCode",
            label: "Referral-Code",
            placeholder: "Enter Referral-Code",
            type: "text",
            ref: inputRefs.referralCode // Assign ref to the field
        },
    ];
    

    

    

      const validateFormData = async (formData) => {
        try {
          await schema.validate(formData, { abortEarly: false });
          return { isValid: true, errors: {} };
        } catch (error) {
          const newErrors = {};
          error.inner.forEach((err) => {
            newErrors[err.path] = err.message;
          });
          return { isValid: false, errors: newErrors };
        }
      };
      

      const func = async (e) => {
        e.preventDefault();
        const validationResult = await validateFormData(formData);
        if (!validationResult.isValid) {
          setValidationErrors(validationResult.errors);
          const firstErrorField = Object.keys(validationResult.errors)[0];
          if (inputRefs[firstErrorField]) {
            inputRefs[firstErrorField].current.focus();
          }
          return;
        }
        console.log("form submitted:", formData);
        setValidationErrors({});
        const action = await dispatch(addBusiness(formData));
        if (!action.payload.success) {
            notifyError(action.payload.message)
        }
        console.log("action.payload:" + JSON.stringify(action.payload.success));
        if (action.payload.success) {
          setStepNumber((prevStepNumber) => prevStepNumber + 1);
          const action = await dispatch(sendUserVerificationLink(formData.email));
          if (action.payload.success) {
            notifySuccess(action.payload.message)
        }
        }
      };
      
    

    const handleCheckBox=(e)=>{
        console.log(e.target.name);
        console.log(e.target.checked);
    }
    
    return (
        // <div className="min-h-[80%] max-w-[60%] rounded-xl border-2 shadow-md">
        <div className="min-h-[80%] max-w-[552px] rounded-xl border-2 shadow-md">
        <Toaster />
            <div className="flex justify-center  border-b-2 py-3">
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white ">
                    1
                </button>
                <div className="mx-3 flex w-40 items-center justify-center">
                    <hr className={`w-full border-2 ${stepNumber>=1?'border-brand-500':''}`} />
                    <hr className={`w-full border-2 ${stepNumber>1?'border-brand-500':''}`} />
                </div>
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
                    2
                </button>
                <div className="mx-3 flex w-40 items-center justify-center">
                    <hr className={`w-full border-2 ${stepNumber>2?'border-brand-500':''}`} />
                    <hr className={`w-full border-2 ${stepNumber>2?'border-brand-500':''}`} />
                </div>
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
                    3
                </button>
            </div>
            {/* 1 */}
            {stepNumber===1 && (<div className="p-6">
                <h1 className="mb-2 text-xl font-medium text-gray-900">
                    Basic Information
                </h1>
                <p className="text-gray-800">
                    Please provide your name, email address, and any other relevant
                    official details to complete the first step of the signup process.
                </p>
                <form onSubmit={func}>
                {/* <form onSubmit={handleSubmit(submitForm)}> */}
                <Form
                    fields={signupFields}
                    formData={formData}
                    onChange={(newFormData) => setFormData(newFormData)}
                    mainDivCss={"grid grid-cols-2 gap-x-8 mb-4"}
                    errors={validationErrors}
                />
                {/* <div className="flex mb-4">
                    <Checkbox required={true}  onClick={handleCheckBox}/>
                    <p className="ml-2 " >By clicking Signup, you agree to our  <span className="text-blue-700 hover:cursor-pointer" onClick={()=>navigate('/auth/terms-and-condition')}>Terms and Conditions.</span></p>
                </div>
                <div className="flex mb-4">
                    <Checkbox required={true}/>
                    <p className="ml-2 ">Send me the updates and marketing emails, You can opt-out at any time from your profile settings.</p>
                </div> */}
                <button className="h-10 w-full rounded-xl bg-brand-500 text-base font-medium text-white my-4" type="submit">
                    signup
                </button>
                </form>
               
                <div className="flex justify-center">
                    <h1 className="my-2">
                        Already have an account?
                        <span
                            className="font-bold text-brand-500 hover:cursor-pointer"
                            onClick={(e) => {
                                navigate("/auth/sign-in");
                            }}
                        >
                            {" "}
                            Signin
                        </span>
                    </h1>
                </div>
            </div>)}
            {/* 2 */}
            {stepNumber===2 &&(<div className="p-6">
                <h1 className="mb-2 text-xl font-medium text-gray-900">
                Verify your email
                </h1>
                {console.log("formData"+JSON.stringify(formData))}
                <p className="text-gray-800">
                An email has been dispatched to your registered email address. Please check your inbox for an email sent by Makthab and proceed to click on the provided link to complete the email verification process.
                </p>
                <div className="flex justify-center">
                    <img src={mailSentImg} alt="" className="h-[350px] " />
                </div>
            </div>)}
        </div>
    );
};

export default Signup;
