
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getEnrollments = createAsyncThunk("enrollment/get", async (query) => {
    console.log(query)

    let filteredQuery = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => value != null && value !== '')
    );
    let params = new URLSearchParams(filteredQuery);

    let response = await fetch(`${process.env.REACT_APP_URL}admin/api/enrollment?${params.toString()}`, {
        headers: createHeaders()
    });
    let json = await response.json();
    return json;
});

export const getRecordEnrollments = createAsyncThunk("recordenrollment/get", async (record) => {

    let response = await fetch(`${process.env.REACT_APP_URL}admin/api/enrollment/record/${record}`, {
        headers: createHeaders()
    });
    let json = await response.json();
    return json;
});

export const getOpenRecordEnrollments = createAsyncThunk("recordenrollment/get", async (query) => {
    const { code, record } = query
    let response = await fetch(`${process.env.REACT_APP_URL}admin/open/enrollment/record/${record}/${code}`);
    let json = await response.json();
    return json;
});

export const addEnrollment = createAsyncThunk("enrollment/add", async (data) => {
    const response = await fetch(`${process.env.REACT_APP_URL}admin/api/enrollment/`, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify(data),
    });
    const json = await response.json();
    return json;
});



export const updateEnrollment = createAsyncThunk("enrollment/update", async (data) => {
    console.log(data, "ID and DATA for update");
    let response = await fetch(`${process.env.REACT_APP_URL}admin/api/enrollment/`, {
        method: 'PUT', // or 'PATCH' depending on your API
        headers: createHeaders(),
        body: JSON.stringify({ ...data }) // Include the id in the request body
    });
    let json = await response.json();
    return json;
});


export const deleteEnrollment = createAsyncThunk("enrollment/delete", async (id) => {
    console.log(id, "ID for delete");
    let response = await fetch(`${process.env.REACT_APP_URL}admin/api/enrollment/${id}`, {
        method: 'DELETE',
        headers: createHeaders(),
    });
    let json = await response.json();
    return json;
});

export const enrollmentsSlice = createSlice({
    name: 'enrollment',
    initialState: {

        value: {
            isError: false,
            isLoading: false,
            status: 'default',
            data: {
                count: 0,
                rows: []
            },
            singleRecordEnrollments: []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRecordEnrollments.fulfilled, (enrollment, action) => {
                enrollment.value.singleRecordEnrollments = action.payload
                console.log(action, "IN CASE HERE")
            })
            .addCase(getEnrollments.fulfilled, (enrollment, action) => {

                enrollment.value.data.rows = action.payload.rows
                enrollment.value.data.count = action.payload.count
                enrollment.value.status = action.payload.message
                console.log('Updated enrollment:', enrollment.value.data.rows);


            })
            .addCase(getEnrollments.pending, (enrollment) => {
                enrollment.status = "Fetching todos. Please wait a moment..."
            })

            .addCase(getEnrollments.rejected, (enrollment) => {
                enrollment.status = "Failed to  fetch data..."
            })
            .addCase(updateEnrollment.fulfilled, (enrollment, action) => {
                console.log(action.payload.message, "action.payload.message")
                enrollment.value.status = action.payload.message
                // find the index of the enrollment with the updated id
                // const index = enrollment.value.data.findIndex(item => item.id === action.payload.id);
                // if (index !== -1) {
                //   // replace the enrollment at the found index with the updated enrollment
                //   enrollment.value.data[index] = action.payload;
                // }
            })
            .addCase(updateEnrollment.pending, (enrollment) => {
                enrollment.status = "Updating enrollment. Please wait a moment...";
            })
            .addCase(updateEnrollment.rejected, (enrollment) => {
                enrollment.status = "Failed to update enrollment...";
            })
            .addCase(deleteEnrollment.fulfilled, (enrollment, action) => {
                // // filter out the enrollment with the deleted id
                // enrollment.value.data = enrollment.value.data.filter(item => item.id !== action.payload.id);
                enrollment.value.status = action.payload.message
            })
            .addCase(deleteEnrollment.pending, (enrollment) => {
                enrollment.status = "Deleting enrollment. Please wait a moment...";
            })
            .addCase(deleteEnrollment.rejected, (enrollment) => {
                enrollment.status = "Failed to delete enrollment...";
            })
            .addCase(addEnrollment.fulfilled, (enrollment, action) => {
                // enrollment.value.data.push(action.payload); // add the new enrollment to the list
            })
            .addCase(addEnrollment.pending, (enrollment) => {
                enrollment.status = "Adding enrollment. Please wait a moment...";
            })
            .addCase(addEnrollment.rejected, (enrollment) => {
                enrollment.status = "Failed to add enrollment...";
            });
    }
})

export default enrollmentsSlice.reducer
