import { createSlice } from '@reduxjs/toolkit'

export const zonesSlice = createSlice({
  name: 'zones',
  initialState: {
    value: [
      {name: "zone1", totalDistricts:10, status: true, desc: "SOME MORE DESC"},
      {name: "zone2",totalDistricts:8, status: true, desc: "SOME MORE DESC"},
      {name: "zone3",totalDistricts:4, status: true, desc: "SOME MORE DESC"},
      {name: "zone4",totalDistricts:6, status: true, desc: "SOME MORE DESC"},
      {name: "zone5",totalDistricts:9, status: true, desc: "SOME MORE DESC"},
    ]
  },
  reducers: {
    addZone: (zone) => {

      zone.value.push({name: "zone", totalDistricts:9, status: false, desc: "DESC"})
    }
  },
})

// Action creators are generated for each case reducer function
export const { addZone } = zonesSlice.actions

export default zonesSlice.reducer