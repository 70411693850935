
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
const apiUrl = process.env.REACT_APP_URL

export const sendResetPasswordLink = createAsyncThunk(
    'forgetPassword/sendResetPasswordLink',
    async (user) => {
      try {
        console.log(user, "USER")
        const response = await fetch(`${apiUrl}admin/open/auth/sendresetpasswordlink`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
  );

  
  export const verifyResetPasswordToken = createAsyncThunk(
    'forgetPassword/verifyResetPasswordToken',
    async (data) => {
      const response = await fetch(`${apiUrl}admin/open/auth/verifyresetpasswordtoken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const apiData = await response.json();
      return apiData;
    }
  );

  export const resetPassword = createAsyncThunk(
    'forgetPassword/resetPassword',
    async (data) => {
      const { token, password, code } = data
      const response = await fetch(`${apiUrl}admin/open/auth/resetpassword/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password, code }),
      });
      const apiData = await response.json();
      console.log('Password updated successfully:', apiData);
      return apiData;
    }
  );
  
  

export const forgetPasswordSlice = createSlice({
    name: 'forgetPassword',
    initialState: {
      status: 'idle',
      error: null,
      resetLinkSent: false,
      loading: false,
    },
    reducers: {},
    /*extraReducers: (builder) => {
      builder
        .addCase(sendResetPasswordLink.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(sendResetPasswordLink.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.resetLinkSent = true; // Set to true when the reset link is sent successfully
        })
        .addCase(sendResetPasswordLink.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || 'Failed to send reset password link';
        });
        .addCase(verifyResetPasswordToken.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.loading = true; // Set loading to true
      })
      .addCase(verifyResetPasswordToken.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false; // Set loading to false
      })
      .addCase(verifyResetPasswordToken.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to verify reset password token';
        state.loading = false; // Set loading to false
      });
    },*/
    extraReducers: (builder) => {
    builder
      .addCase(sendResetPasswordLink.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        
      })
      .addCase(sendResetPasswordLink.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.resetLinkSent = true;
        
      })
      .addCase(sendResetPasswordLink.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to send reset password link';
        
      });

    builder
      .addCase(verifyResetPasswordToken.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.loading = true; // Set loading to true
      })
      .addCase(verifyResetPasswordToken.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loading = false; // Set loading to false
      })
      .addCase(verifyResetPasswordToken.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to verify reset password token';
        state.loading = false; // Set loading to false
      });
  },
  });


export default forgetPasswordSlice.reducer


