import React, { useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { getClassStudents } from '../../redux/actions/home';
import { useDispatch, useSelector } from 'react-redux';

function Classes(props) {
    const { classes } = props;
    const navigate = useNavigate();
    console.log(props, "PROPS")
    return (
        <div className=' bg-lightPrimary h-screen p-4'>
            <div className='p-2 bg-white rounded-xl '>
                {
                    classes?.length === 0 &&
                    <p className='text-center mt-5 mb-5'> No classes assigned </p>
                }
                {
                    classes?.map((classRoom, index) => {
                        return (
                            <ul
                                key={classRoom.id}
                                className='h-3/12 my-5 border border-brand-500 shadow-gray-200 shadow-xl w-full p-4 rounded-xl flex items-center justify-between'
                            >
                                <Link
                                    to={`/parent/attendance/${classRoom.value}`}
                                    className={`p-5 m-1 mb-0 text-brand-500 text-xl flex justify-center text-center  transition-all duration-150 ease-in-out }`}
                                >
                                    <li className='font-poppins'> {index + 1 + ". " + classRoom?.label} </li>
                                </Link>
                            </ul>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Classes;
