
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getStudents = createAsyncThunk("student/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/student?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addStudent = createAsyncThunk("student/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/student/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateStudent = createAsyncThunk("student/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/student/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteStudent = createAsyncThunk("student/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/student/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const studentsSlice = createSlice({
  name: 'studentes',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudents.fulfilled, (student, action) => {
     
        student.value.data.rows = action.payload.rows
        student.value.data.count = action.payload.count
        student.value.status = action.payload.message
        console.log('Updated student:', student.value.data.rows);

        
      })
      .addCase(getStudents.pending, (student) => {
        student.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getStudents.rejected, (student) => {
        student.status = "Failed to  fetch data..."
      })
      .addCase(updateStudent.fulfilled, (student, action) => {
        console.log(action.payload.message, "action.payload.message")
        student.value.status = action.payload.message
        // find the index of the student with the updated id
        // const index = student.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the student at the found index with the updated student
        //   student.value.data[index] = action.payload;
        // }
      })
      .addCase(updateStudent.pending, (student) => {
        student.status = "Updating student. Please wait a moment...";
      })
      .addCase(updateStudent.rejected, (student) => {
        student.status = "Failed to update student...";
      })
      .addCase(deleteStudent.fulfilled, (student, action) => {
        // // filter out the student with the deleted id
        // student.value.data = student.value.data.filter(item => item.id !== action.payload.id);
        student.value.status = action.payload.message
      })
      .addCase(deleteStudent.pending, (student) => {
        student.status = "Deleting student. Please wait a moment...";
      })
      .addCase(deleteStudent.rejected, (student) => {
        student.status = "Failed to delete student...";
      })
      .addCase(addStudent.fulfilled, (student, action) => {
        // student.value.data.push(action.payload); // add the new student to the list
     })
     .addCase(addStudent.pending, (student) => {
        student.status = "Adding student. Please wait a moment...";
     })
     .addCase(addStudent.rejected, (student) => {
        student.status = "Failed to add student...";
     });
  }
})

export default studentsSlice.reducer
