// import React, { useEffect, useState , useRef} from "react";
// import Form from "components/form";
// import { useNavigate, useLocation } from "react-router-dom";
// import { useForm } from "react-hook-form";
// import {addBusiness, syncUserModels, updateBusiness} from '../../../redux/actions/business'
// import {verifyUserVerificationToken, signupWelcomeMail} from '../../../redux/actions/auth'
// import { useDispatch, useSelector } from "react-redux";
// // import mailSentImg from '../../../assets/img/auth/mail-sent.png'
// import mailSentImg from '../../../assets/img/auth/Mail sent-amico.png'
// // import { FaCircleCheck } from "react-icons/fa"
// import { FaCheckCircle } from "react-icons/fa";
// import * as yup from 'yup'


// const VerifyUser = () => {
//     const schema = yup.object().shape({
//         code: yup.string().nullable(false).required('Business Code is required'),
//         type: yup.string().nullable(false).required('Business Type is required'),
//         username: yup.string().nullable(false).required('Admin User Name is required'),
//         password: yup.string().nullable(false).required('Admin Password is required'),
//         confirmPassword: yup
//           .string()
//           .nullable(false)
//           .required('Confirm Password is required')
//           .oneOf([yup.ref('password'), null], 'Passwords must match')
//       });

//       const inputRefs = {
//         code: useRef(null),
//         type: useRef(null),
//         username: useRef(null),
//         password: useRef(null),
//         confirmPassword: useRef(null)
//     };

//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const token = searchParams.get("token");
//   console.log("token: "+token);

//     const [formData, setFormData] = useState({});
//     const [validationErrors, setValidationErrors] = useState({});
//     const [stepNumber, setStepNumber] = useState(3);
//     const [isLoading, setIsLoading] = useState(true);

//     useEffect(() => {
//       const verifyToken = async () => {
//         console.log('hello');
//         try {
//           const response = await dispatch(verifyUserVerificationToken(token));
//           console.log("response of verifyResetPasswordToken :", JSON.stringify(response));
//           // console.log("isValidToken redux updated :", JSON.stringify(isValidToken));
          
//           if(!response.payload.success){
//             console.log("invalid token!!!!");
//             //notifyError("invalid token");
//             navigate("/auth/sign-in");
          
//           }
//           setIsLoading(false)
          
//           // setValidToken(true);
//         } catch (error) {
//           console.error("TRYCATCH Error verifying reset password token:", error);
//         }
//       };
  
//       verifyToken();
//     }, [dispatch, token]);

//     const signupFields = [
//         {
//             id: "code",
//             name: "code",
//             label: "Business Code",
//             placeholder: "Enter Business Code",
//             type: "text",
//             ref: inputRefs.code
//         },
//         {
//           id: "type",
//           name: "type",
//           label: "Business Type",
//           placeholder: "Enter Business Type",
//           type: "text",
//           ref: inputRefs.type
//       },
//         {
//             id: "username",
//             name: "username",
//             label: "Admin User Name",
//             placeholder: "Enter User Name",
//             type: "text",
//             ref: inputRefs.username
//         },
//         {
//             id: "password",
//             name: "password",
//             label: "Admin Password",
//             placeholder: "Enter password",
//             type: "password",
//             ref: inputRefs.password

//         },
//         {
//             id: "confirmPassword",
//             name: "confirmPassword",
//             label: "Confirm Admin Password",
//             placeholder: "Enter confirmPassword",
//             type: "password",
//             ref: inputRefs.confirmPassword

//         },
//     ];

    
//         console.log('check 123:'+JSON.stringify({token,username:formData.username,password:formData.password}))
    

//     const func= async(e)=>{
//         e.preventDefault()
//         try {
//             await schema.validate(formData, {abortEarly:false})
//             console.log("form submitted:", formData);
//             setValidationErrors({})
//         console.log('clicked');
//         // console.log(e.target[0].value);
//         console.log(formData);
//       const action = await dispatch(updateBusiness({formData,token}));
//       console.log("action.payload:"+JSON.stringify(action.payload.success));

//       if(action.payload.success){
//         setStepNumber(prevStepNumber => prevStepNumber + 1);
//         const action = await dispatch(syncUserModels(formData));
//         console.log("sync action.payload:"+JSON.stringify(action.payload));
//         const sendMail = await dispatch(signupWelcomeMail({token,username:formData.username,password:formData.password}));


//       }
//     } catch (error) {
//         const newErrors = {}

//         error.inner.forEach((err)=>{
//             newErrors[err.path] = err.message
//         })
        
//         setValidationErrors(newErrors )

//         if (error.inner.length > 0) {
//             const firstErrorField = error.inner[0].path;
//             if (inputRefs[firstErrorField]) {
//                 inputRefs[firstErrorField].current.focus();
//             }
//         }
//     }
      
//     }

//     if (isLoading) {
//       return <div className="flex items-center justify-center h-[70%]">Loading...</div>;
//     }
//   return (
//     <div className="min-h-[80%] max-w-[552px] rounded-xl border-2 shadow-md">
//             {/* <div className="flex justify-center  border-b-2 py-3">
//                 <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
//                     1
//                 </button>
//                 <div className="mx-3 flex w-40 items-center justify-center">
//                     <hr className="w-full border-2" />
//                 </div>
//                 <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
//                     2
//                 </button>
//                 <div className="mx-3 flex w-40 items-center justify-center">
//                     <hr className="w-full border-2 " />
//                 </div>
//                 <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
//                     3
//                 </button>
//             </div> */}
//             <div className="flex justify-center  border-b-2 py-3">
//                 <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
//                     1
//                 </button>
//                 <div className="mx-3 flex w-40 items-center justify-center">
//                     <hr className={`w-full border-2 ${stepNumber>=1?'border-brand-500':''}`} />
//                     <hr className={`w-full border-2 ${stepNumber>1?'border-brand-500':''}`} />
//                 </div>
//                 <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
//                     2
//                 </button>
//                 <div className="mx-3 flex w-40 items-center justify-center">
//                     <hr className={`w-full border-2 ${stepNumber>=2?'border-brand-500':''}`} />
//                     <hr className={`w-full border-2 ${stepNumber>3?'border-brand-500':''}`} />

//                 </div>
//                 <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
//                     3
//                 </button>
//             </div>
//             {/* 1 */}
//             {stepNumber===3 && (<div className="p-6">
//                 <h1 className="mb-2 text-xl font-medium text-gray-900">
//                     Basic Information
//                 </h1>
//                 <p className="text-gray-800">
//                     Please provide your name, email address, and any other relevant
//                     personal details to complete the first step of the signup process.
//                 </p>
//                 <form onSubmit={func}>
//                 <Form
//                     fields={signupFields}
//                     formData={formData}
//                     onChange={(newFormData) => setFormData(newFormData)}
//                     mainDivCss={"grid grid-cols-2 gap-x-8 "}
//                     errors={validationErrors}
//                     // register={register}
//                 />
//                 <button className="h-10 w-full rounded-xl bg-brand-500 text-base font-medium text-white" type="submit">
//                     Create Business And Continue
//                 </button>
//                 </form>
               
//                 {/* <div className="flex justify-center">
//                     <h1 className="my-2">
//                         Already have an account?
//                         <span
//                             className="font-bold text-brand-500 hover:cursor-pointer"
//                             onClick={(e) => {
//                                 navigate("/auth/sign-in");
//                             }}
//                         >
//                             {" "}
//                             Signin
//                         </span>
//                     </h1>
//                 </div> */}
//             </div>)}
//             {/* 2 */}
//             {stepNumber===4 &&(<div className="p-6 flex flex-col justify-center items-center">
//                 <h1 className="mb-2 text-xl font-medium text-gray-900">
//                 Account setup done
//                 </h1>
//                 <FaCheckCircle className="text-brand-800 h-24 w-24 my-2"/>

//                 <p className="text-gray-800 pt-3">
//                 Congratulations! You have successfully completed the signup process. You can now access your account and start using all of the features and tools available to you.
//                 </p>
//                 <button className="h-10 w-full rounded-xl bg-brand-500 text-base font-medium text-white mt-4" onClick={navigate('/auth/sign-in')}>
//                     Continue to
//                 </button>
                
//             </div>)}
//         </div>
//   )
// }

// export default VerifyUser













import React, { useEffect, useState , useRef} from "react";
import Form from "components/form";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import {addBusiness, syncUserModels, updateBusiness} from '../../../redux/actions/business'
import {verifyUserVerificationToken, signupWelcomeMail} from '../../../redux/actions/auth'
import { useDispatch, useSelector } from "react-redux";
// import mailSentImg from '../../../assets/img/auth/mail-sent.png'
import mailSentImg from '../../../assets/img/auth/Mail sent-amico.png'
// import { FaCircleCheck } from "react-icons/fa"
import { FaCheckCircle } from "react-icons/fa";
import * as yup from 'yup'


const VerifyUser = () => {
    const schema = yup.object().shape({
        code: yup.string().nullable(false).required('Business Code is required'),
        type: yup.string().nullable(false).required('Business Type is required'),
        username: yup.string().nullable(false).required('Admin User Name is required'),
        password: yup.string().nullable(false).required('Admin Password is required'),
        confirmPassword: yup
          .string()
          .nullable(false)
          .required('Confirm Password is required')
          .oneOf([yup.ref('password'), null], 'Passwords must match')
      });

      const inputRefs = {
        code: useRef(null),
        type: useRef(null),
        username: useRef(null),
        password: useRef(null),
        confirmPassword: useRef(null)
    };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  console.log("token: "+token);

    const [formData, setFormData] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [stepNumber, setStepNumber] = useState(3);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const verifyToken = async () => {
        console.log('hello');
        try {
          const response = await dispatch(verifyUserVerificationToken(token));
          console.log("response of verifyResetPasswordToken :", JSON.stringify(response));
          // console.log("isValidToken redux updated :", JSON.stringify(isValidToken));
          
          if(!response.payload.success){
            console.log("invalid token!!!!");
            //notifyError("invalid token");
            navigate("/auth/sign-in");
          
          }
          setIsLoading(false)
          
          // setValidToken(true);
        } catch (error) {
          console.error("TRYCATCH Error verifying reset password token:", error);
        }
      };
  
      verifyToken();
    }, [dispatch, token]);



    useEffect(() => {
        const validate = async () => {
          const result = await validateFormData(formData);
          if (!result.isValid) {
            setValidationErrors(result.errors);
          } else {
            setValidationErrors({});
          }
        };
        if(Object.keys(validationErrors).length){
            validate();
        }
      }, [formData]);

    const signupFields = [
        {
            id: "code",
            name: "code",
            label: "Maktab Code",
            placeholder: "Enter Maktab Code",
            type: "text",
            ref: inputRefs.code
        },
        {
          id: "type",
          name: "type",
          label: "Maktab Type",
          placeholder: "Enter Maktab Type",
          type: "text",
          ref: inputRefs.type
      },
        {
            id: "username",
            name: "username",
            label: "Admin User Name",
            placeholder: "Enter User Name",
            type: "text",
            ref: inputRefs.username
        },
        {
            id: "password",
            name: "password",
            label: "Admin Password",
            placeholder: "Enter password",
            type: "password",
            ref: inputRefs.password

        },
        {
            id: "confirmPassword",
            name: "confirmPassword",
            label: "Confirm Admin Password",
            placeholder: "Enter confirmPassword",
            type: "password",
            ref: inputRefs.confirmPassword

        },
    ];

    
    //     console.log('check 123:'+JSON.stringify({token,username:formData.username,password:formData.password}))
    

    // const func= async(e)=>{
    //     e.preventDefault()
    //     try {
    //         await schema.validate(formData, {abortEarly:false})
    //         console.log("form submitted:", formData);
    //         setValidationErrors({})
    //     console.log('clicked');
    //     // console.log(e.target[0].value);
    //     console.log(formData);
    //   const action = await dispatch(updateBusiness({formData,token}));
    //   console.log("action.payload:"+JSON.stringify(action.payload.success));

    //   if(action.payload.success){
    //     setStepNumber(prevStepNumber => prevStepNumber + 1);
    //     const action = await dispatch(syncUserModels(formData));
    //     console.log("sync action.payload:"+JSON.stringify(action.payload));
    //     const sendMail = await dispatch(signupWelcomeMail({token,username:formData.username,password:formData.password}));


    //   }
    // } catch (error) {
    //     const newErrors = {}

    //     error.inner.forEach((err)=>{
    //         newErrors[err.path] = err.message
    //     })
        
    //     setValidationErrors(newErrors )

    //     if (error.inner.length > 0) {
    //         const firstErrorField = error.inner[0].path;
    //         if (inputRefs[firstErrorField]) {
    //             inputRefs[firstErrorField].current.focus();
    //         }
    //     }
    // }
      
    // }


    const validateFormData = async (formData) => {
        try {
          await schema.validate(formData, { abortEarly: false });
          return { isValid: true, errors: {} };
        } catch (error) {
          const newErrors = {};
          error.inner.forEach((err) => {
            newErrors[err.path] = err.message;
          });
          return { isValid: false, errors: newErrors };
        }
      };
      

      const func = async (e) => {
        e.preventDefault();
        const validationResult = await validateFormData(formData);
        if (!validationResult.isValid) {
          setValidationErrors(validationResult.errors);
          const firstErrorField = Object.keys(validationResult.errors)[0];
          if (inputRefs[firstErrorField]) {
            inputRefs[firstErrorField].current.focus();
          }
          return;
        }
        console.log("form submitted:", formData);
        setValidationErrors({});
        const action = await dispatch(updateBusiness({formData,token}));
      console.log("action.payload:"+JSON.stringify(action.payload.success));

      if(action.payload.success){
        setStepNumber(prevStepNumber => prevStepNumber + 1);
        const action = await dispatch(syncUserModels(formData));
        console.log("sync action.payload:"+JSON.stringify(action.payload));
        const sendMail = await dispatch(signupWelcomeMail({token,username:formData.username,password:formData.password}));
      }
      };

    if (isLoading) {
      return <div className="flex items-center justify-center h-[70%]">Loading...</div>;
    }
  return (
    <div className="min-h-[80%] max-w-[552px] rounded-xl border-2 shadow-md">
            {/* <div className="flex justify-center  border-b-2 py-3">
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
                    1
                </button>
                <div className="mx-3 flex w-40 items-center justify-center">
                    <hr className="w-full border-2" />
                </div>
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
                    2
                </button>
                <div className="mx-3 flex w-40 items-center justify-center">
                    <hr className="w-full border-2 " />
                </div>
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
                    3
                </button>
            </div> */}
            <div className="flex justify-center  border-b-2 py-3">
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
                    1
                </button>
                <div className="mx-3 flex w-40 items-center justify-center">
                    <hr className={`w-full border-2 ${stepNumber>=1?'border-brand-500':''}`} />
                    <hr className={`w-full border-2 ${stepNumber>1?'border-brand-500':''}`} />
                </div>
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
                    2
                </button>
                <div className="mx-3 flex w-40 items-center justify-center">
                    <hr className={`w-full border-2 ${stepNumber>=2?'border-brand-500':''}`} />
                    <hr className={`w-full border-2 ${stepNumber>3?'border-brand-500':''}`} />

                </div>
                <button className="flex h-7 w-7 items-center justify-center rounded-full bg-brand-500 text-white">
                    3
                </button>
            </div>
            {/* 1 */}
            {stepNumber===3 && (<div className="p-6">
                <h1 className="mb-2 text-xl font-medium text-gray-900">
                    Basic Information
                </h1>
                <p className="text-gray-800">
                    Please provide your name, email address, and any other relevant
                    personal details to complete the first step of the signup process.
                </p>
                <form onSubmit={func}>
                <Form
                    fields={signupFields}
                    formData={formData}
                    onChange={(newFormData) => setFormData(newFormData)}
                    mainDivCss={"grid grid-cols-2 gap-x-8 "}
                    errors={validationErrors}
                    // register={register}
                />
                <button className="h-10 w-full rounded-xl bg-brand-500 text-base font-medium text-white" type="submit">
                    Continue
                </button>
                </form>
               
                {/* <div className="flex justify-center">
                    <h1 className="my-2">
                        Already have an account?
                        <span
                            className="font-bold text-brand-500 hover:cursor-pointer"
                            onClick={(e) => {
                                navigate("/auth/sign-in");
                            }}
                        >
                            {" "}
                            Signin
                        </span>
                    </h1>
                </div> */}
            </div>)}
            {/* 2 */}
            {stepNumber===4 &&(<div className="p-6 flex flex-col justify-center items-center">
                <h1 className="mb-2 text-xl font-medium text-gray-900">
                Account setup done
                </h1>
                <FaCheckCircle className="text-brand-800 h-24 w-24 my-2"/>

                <p className="text-gray-800 pt-3">
                Congratulations! You have successfully completed the signup process. You can now access your account and start using all of the features and tools available to you.
                </p>
                <button className="h-10 w-full rounded-xl bg-brand-500 text-base font-medium text-white mt-4" onClick={()=>navigate('/auth/sign-in')}>
                    Login
                </button>
                
            </div>)}
        </div>
  )
}

export default VerifyUser