
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getSubjects = createAsyncThunk("subject/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/subject?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addSubject = createAsyncThunk("subject/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/subject/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateSubject = createAsyncThunk("subject/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/subject/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteSubject = createAsyncThunk("subject/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/subject/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const subjectsSlice = createSlice({
  name: 'subjectes',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubjects.fulfilled, (subject, action) => {
     
        subject.value.data.rows = action.payload.rows
        subject.value.data.count = action.payload.count
        subject.value.status = action.payload.message
        console.log('Updated subject:', subject.value.data.rows);

        
      })
      .addCase(getSubjects.pending, (subject) => {
        subject.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getSubjects.rejected, (subject) => {
        subject.status = "Failed to  fetch data..."
      })
      .addCase(updateSubject.fulfilled, (subject, action) => {
        console.log(action.payload.message, "action.payload.message")
        subject.value.status = action.payload.message
        // find the index of the subject with the updated id
        // const index = subject.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the subject at the found index with the updated subject
        //   subject.value.data[index] = action.payload;
        // }
      })
      .addCase(updateSubject.pending, (subject) => {
        subject.status = "Updating subject. Please wait a moment...";
      })
      .addCase(updateSubject.rejected, (subject) => {
        subject.status = "Failed to update subject...";
      })
      .addCase(deleteSubject.fulfilled, (subject, action) => {
        // // filter out the subject with the deleted id
        // subject.value.data = subject.value.data.filter(item => item.id !== action.payload.id);
        subject.value.status = action.payload.message
      })
      .addCase(deleteSubject.pending, (subject) => {
        subject.status = "Deleting subject. Please wait a moment...";
      })
      .addCase(deleteSubject.rejected, (subject) => {
        subject.status = "Failed to delete subject...";
      })
      .addCase(addSubject.fulfilled, (subject, action) => {
        // subject.value.data.push(action.payload); // add the new subject to the list
     })
     .addCase(addSubject.pending, (subject) => {
        subject.status = "Adding subject. Please wait a moment...";
     })
     .addCase(addSubject.rejected, (subject) => {
        subject.status = "Failed to add subject...";
     });
  }
})

export default subjectsSlice.reducer
