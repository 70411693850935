import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateFCMtoken } from "../src/redux/actions/auth";
import { initializeApp } from "firebase/app";
import { onBackgroundMessage } from "firebase/messaging/sw";
import { getMessaging, getToken, onMessage, setBackgroundMessageHandler } from "firebase/messaging";

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import ParentLayout from "layouts/parent";
import OpenLayout from "layouts/open";
import AuthLayout from "layouts/auth";
import { validateToken } from './redux/actions/auth';

const App = () => {
  const [fcmToken, setFcmToken] = React.useState("")
  // const [defaultRoute, setDefaultRoute] = React.useState("")
  const dispatch = useDispatch()
  // let defaultRoute
  
  // React.useEffect(() => {
  //   const validate=async() =>{
  //     const action = await dispatch(validateToken())
  //     console.log("action ..."+JSON.stringify(action.payload.user.role.name));
  //     defaultRoute = (action.payload.user.role.name)
  //     console.log("defaultRoute:: "+(defaultRoute));

  //   }
  //   validate()
  // }, [])

  React.useEffect(() => {
    dispatch( updateFCMtoken({fcmToken}) )
  }, [fcmToken])


  const firebaseConfig = {
    apiKey: "AIzaSyB_Rzw0PXLANxj5Azm86dIBtCQdSzDTOdA",
    authDomain: "maktab-c2f56.firebaseapp.com",
    projectId: "maktab-c2f56",
    storageBucket: "maktab-c2f56.appspot.com",
    messagingSenderId: "490973311187",
    appId: "1:490973311187:web:ee237ba6951632a4242426",
    measurementId: "G-9ZSZCYQH85"
  };


  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging();

  onMessage(messaging, (payload) => {
    if(payload) {
      navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
        registration.showNotification(
            payload.notification.title,
            payload.notification
        )
    });
      console.log('Message received. ', payload);
    } else {
      console.log('nothing received');
    }
    // ...
  });

  
  function requestPermission() {
    console.log('Requesting permission...');
    try {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
      }
    })
    } catch( err ) {
      console.log(err)
    }
  }
  requestPermission()

  getToken(messaging, { vapidKey: 'BI5rCpt0KwlhX-xu8virZgWN9ZT6f9Z30xpykmlL-z6XZuuJAT9x4hwxt0MDYzyCt8L5Lk3-p3zuQfmCMqyN8Us' }).then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // ...
      setFcmToken(currentToken)
      // fcmToken = currentToken
      console.log(currentToken, "currentTokencurrentTokencurrentToken");
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });

  if ('serviceWorker' in navigator) {
    console.log('Service Worker registering:');
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then(registration => {
                console.log('Service Worker registered:', registration);
            })
            .catch(error => {
                console.error('Service Worker registration failed:', error);
            });
    });   
}


  return (
    <Routes>

      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="parent/*" element={<ParentLayout />} />
      <Route path="open/*" element={<OpenLayout />} />
      <Route path="rtl/*" element={<RtlLayout />} />
      <Route path="/" element={<Navigate to="/admin" replace />} />
      {/* <Route path="/" element={defaultRoute=="Parent" ? <Navigate to="/parent" replace /> : <Navigate to="/admin" replace />} /> */}


    </Routes>

  );
};

export default App;
