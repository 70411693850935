
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from './headers';


// export const getStates = createAsyncThunk("state/get", async ({ searchTerm, status }) => {
//   let url = `${process.env.REACT_APP_URL}api/state`;
//   let isFirstParameter = true;

//   if (searchTerm) {
//     url += `?name=${searchTerm}`;
//     isFirstParameter = false;
//   }

//   if (status !== undefined) {
//     if (isFirstParameter) {
//       url += `?status=${status}`;
//     } else {
//       url += `&status=${status}`;
//     }
//   }

//   let response = await fetch(url, {
//     headers: createHeaders()
//   });

//   let json = await response.json();
//   console.log(json);
//   return json;
// });

// export const getStates = createAsyncThunk("state/get", async ({ query }) => {
//   let url = `${process.env.REACT_APP_URL}api/state`;
//   let isFirstParameter = true;

//   if (query[0]) {
//     url += `?name=${query[0]}`;
//     isFirstParameter = false;
//   }
  
//   if (query[1] !== undefined) {
//     if (isFirstParameter) {
//       url += `?status=${query[1]}`;
//     } else {
//       url += `&status=${query[1]}`;
//     }
//   }

//   let response = await fetch(url, {
//     headers: createHeaders()
//   });

//   let json = await response.json();
//   console.log(json);
//   return json;
// });

export const getStates = createAsyncThunk("state/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}api/state?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addState = createAsyncThunk("state/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}api/state/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateState = createAsyncThunk("state/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}api/state/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteState = createAsyncThunk("state/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}api/state/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const statesSlice = createSlice({
  name: 'state',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        activeCount: 0,
        inactiveCount: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStates.fulfilled, (state, action) => {
        console.log()
     
        state.value.data.rows = action.payload.rows
        state.value.data.count = action.payload.count
        state.value.data.activeCount=action.payload.activeStates
        state.value.data.inactiveCount=action.payload.inactiveStates
        state.value.status = action.payload.message
        console.log('Updated state:', state);

        
      })
      .addCase(getStates.pending, (state) => {
        state.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getStates.rejected, (state) => {
        state.status = "Failed to  fetch data..."
      })
      .addCase(updateState.fulfilled, (state, action) => {
        console.log(action.payload.message, "action.payload.message")
        state.value.status = action.payload.message
        // find the index of the state with the updated id
        // const index = state.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the state at the found index with the updated state
        //   state.value.data[index] = action.payload;
        // }
      })
      .addCase(updateState.pending, (state) => {
        state.status = "Updating state. Please wait a moment...";
      })
      .addCase(updateState.rejected, (state) => {
        state.status = "Failed to update state...";
      })
      .addCase(deleteState.fulfilled, (state, action) => {
        // // filter out the state with the deleted id
        // state.value.data = state.value.data.filter(item => item.id !== action.payload.id);
        state.value.status = action.payload.message
      })
      .addCase(deleteState.pending, (state) => {
        state.status = "Deleting state. Please wait a moment...";
      })
      .addCase(deleteState.rejected, (state) => {
        state.status = "Failed to delete state...";
      })
      .addCase(addState.fulfilled, (state, action) => {
        // state.value.data.push(action.payload); // add the new state to the list
     })
     .addCase(addState.pending, (state) => {
        state.status = "Adding state. Please wait a moment...";
     })
     .addCase(addState.rejected, (state) => {
        state.status = "Failed to add state...";
     });
  }
})

export default statesSlice.reducer
