
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getFields = createAsyncThunk("field/get", async (query) => {
    console.log(query)

    let filteredQuery = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => value != null && value !== '')
    );
    let params = new URLSearchParams(filteredQuery);

    let response = await fetch(`${process.env.REACT_APP_URL}admin/api/field?${params.toString()}`, {
        headers: createHeaders()
    });
    let json = await response.json();
    return json;
});

export const getRecordFields = createAsyncThunk("recordfield/get", async (record) => {

    let response = await fetch(`${process.env.REACT_APP_URL}admin/api/field/record/${record}`, {
        headers: createHeaders()
    });
    let json = await response.json();
    return json;
});

export const getOpenRecordFields = createAsyncThunk("recordfield/get", async (query) => {
    const { code, record } = query
    let response = await fetch(`${process.env.REACT_APP_URL}admin/open/field/record/${record}/${code}`);
    let json = await response.json();
    return json;
});

export const addField = createAsyncThunk("field/add", async (data) => {
    const response = await fetch(`${process.env.REACT_APP_URL}admin/api/field/`, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify(data),
    });
    const json = await response.json();
    return json;
});



export const updateField = createAsyncThunk("field/update", async (data) => {
    console.log(data, "ID and DATA for update");
    let response = await fetch(`${process.env.REACT_APP_URL}admin/api/field/`, {
        method: 'PUT', // or 'PATCH' depending on your API
        headers: createHeaders(),
        body: JSON.stringify({ ...data }) // Include the id in the request body
    });
    let json = await response.json();
    return json;
});


export const deleteField = createAsyncThunk("field/delete", async (id) => {
    console.log(id, "ID for delete");
    let response = await fetch(`${process.env.REACT_APP_URL}admin/api/field/${id}`, {
        method: 'DELETE',
        headers: createHeaders(),
    });
    let json = await response.json();
    return json;
});

export const fieldsSlice = createSlice({
    name: 'field',
    initialState: {

        value: {
            isError: false,
            isLoading: false,
            status: 'default',
            data: {
                count: 0,
                rows: []
            },
            singleRecordFields: []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRecordFields.fulfilled, (field, action) => {
                field.value.singleRecordFields = action.payload
                console.log(action, "IN CASE HERE")
            })
            .addCase(getFields.fulfilled, (field, action) => {

                field.value.data.rows = action.payload.rows
                field.value.data.count = action.payload.count
                field.value.status = action.payload.message
                console.log('Updated field:', field.value.data.rows);


            })
            .addCase(getFields.pending, (field) => {
                field.status = "Fetching todos. Please wait a moment..."
            })

            .addCase(getFields.rejected, (field) => {
                field.status = "Failed to  fetch data..."
            })
            .addCase(updateField.fulfilled, (field, action) => {
                console.log(action.payload.message, "action.payload.message")
                field.value.status = action.payload.message
                // find the index of the field with the updated id
                // const index = field.value.data.findIndex(item => item.id === action.payload.id);
                // if (index !== -1) {
                //   // replace the field at the found index with the updated field
                //   field.value.data[index] = action.payload;
                // }
            })
            .addCase(updateField.pending, (field) => {
                field.status = "Updating field. Please wait a moment...";
            })
            .addCase(updateField.rejected, (field) => {
                field.status = "Failed to update field...";
            })
            .addCase(deleteField.fulfilled, (field, action) => {
                // // filter out the field with the deleted id
                // field.value.data = field.value.data.filter(item => item.id !== action.payload.id);
                field.value.status = action.payload.message
            })
            .addCase(deleteField.pending, (field) => {
                field.status = "Deleting field. Please wait a moment...";
            })
            .addCase(deleteField.rejected, (field) => {
                field.status = "Failed to delete field...";
            })
            .addCase(addField.fulfilled, (field, action) => {
                // field.value.data.push(action.payload); // add the new field to the list
            })
            .addCase(addField.pending, (field) => {
                field.status = "Adding field. Please wait a moment...";
            })
            .addCase(addField.rejected, (field) => {
                field.status = "Failed to add field...";
            });
    }
})

export default fieldsSlice.reducer
