import { React, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { getStudyMaterial } from "../../redux/actions/home"
import { useDispatch, useSelector } from 'react-redux';
import { IoEnter } from 'react-icons/io5';

function SingleSubject() {
    // const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { id } = useParams()
    useEffect(() => {
        dispatch(getStudyMaterial(id));
    }, [dispatch]);
    const materials = useSelector((state) => state.home.value.studyMaterial || []);
    console.log(materials, "materials")
    // const { subjects, enrolledClass } = location.state
    return (
        <div className=' bg-lightPrimary h-screen p-4'>
            <div className='p-2 bg-white rounded-xl '>
                <p className='text-xl font-regular text-brand-500 text-center m-3' >
                    Materials for <span className='font-bold'> {materials.name} </span>
                </p>
                {
                    materials.studymaterials && materials.studymaterials.length == 0 && 
                    <p className='text-center mt-5'> No Materials found </p>
                }
                {
                   materials.studymaterials &&  materials.studymaterials.map((material, index) => {
                        return (
                            <div
                                className={`h-3/12 my-5 border border-brand-500 shadow-gray-200  shadow-xl w-full mt-2 p-4  rounded-xl flex items-center justify-between`}
                                onClick={() => { window.open(material.link, '_blank') }}
                            >
                                <div>
                                    <h1 className='font-poppins font-semibold text-2xl'> {material.name} </h1>
                                </div>
                                <div className='bg-brand-500 shadow p-3 text-white rounded-xl flex flex-col items-center '>
                                    <IoEnter className='text-2xl' />
                                    <p className='text-xs'> Open Material </p>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default SingleSubject