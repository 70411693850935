import { createSlice } from '@reduxjs/toolkit';

export const signInSlice = createSlice({
  name: 'signIn',
  initialState: {
    username: '',
    password: '',
    error: null,
    // showDialog: false,
    errorMessage: '',
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    // setShowDialog: (state, action) => {
    //   state.showDialog = action.payload;
    // },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { setUsername, setPassword, setError, setErrorMessage } = signInSlice.actions;

export default signInSlice.reducer;
